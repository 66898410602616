import React from "react";
import useFormChange from "utils/useFormChange";

// reactstrap components
import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Container,
  Alert,
} from "reactstrap";

// core components
import SuperButton from "components/UI/SuperButton";
import Icon from "components/UI/Icon";
import { post } from "utils/api";

export default function ForgotPasswordModal({ isOpen, onClose }) {
  const [formData, handleChange] = useFormChange();
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  function handleSubmit(event) {
    event.preventDefault();
    reset();
    const { name, email } = formData;
    if (!name || !email) {
      setErrorMessage('Please provide your name and email');
    } else {
      post('/forgotPassword', formData).then(() => {
        setSuccess(true);
      }).catch(() => {
        setErrorMessage('Something went wrong. Try again later.');
      });
    }
  }

  function reset() {
    setSuccess(false);
    setErrorMessage(false);
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="md">
      <div className="modal-header">
        <button
          className="close"
          type="button"
          onClick={() => {
            reset();
            onClose();
          }}
          title="Close"
        >
          <span>×</span>
        </button>
        <h5
          className="modal-title"
        >
          Password Recovery
        </h5>
      </div>
      <ModalBody>
        <Alert color="success" isOpen={isSuccess}>
          <Container>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={reset}
            >
              <Icon icon="nc-simple-remove" />
            </button>
            <span><Icon icon="nc-check-2"/> Thanks, we'll be in touch soon.</span>
          </Container>
        </Alert>
        <Alert color="danger" isOpen={!!errorMessage}>
          <Container>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={reset}
            >
              <Icon icon="nc-simple-remove" />
            </button>
            <span><Icon icon="fa-exclamation-circle"/> {errorMessage}</span>
          </Container>
        </Alert>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Name <span className="icon-danger">*</span></Label>
            <Input placeholder="Name" name="name" onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Label>Email <span className="icon-danger">*</span></Label>
            <Input placeholder="Email" name="email" onChange={handleChange} />
          </FormGroup>
          <div className="text-center ml-auto mr-auto" md="8">
            <SuperButton className="btn-round" color="danger">
              <Icon icon="fa-key" /> Recover Password
            </SuperButton>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
