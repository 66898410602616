import React from "react";
import App from "components/App.js";
import Icon from 'components/UI/Icon';
import useFormChange from "utils/useFormChange";
import { post } from "utils/api";

// reactstrap components
import {
  Alert,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";

// core components
import SuperButton from "components/UI/SuperButton.js";

export default function RegisterPage() {
  const [hasSent, setSent] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [shake, setShake] = React.useState(false);
  const [needsAddress, setNeedsAddress] = React.useState(false);
  const [formData, handleChange] = useFormChange({
    status: 'Both',
    subscribe: true
  });
  const [submitIcon, setSubmitIcon] = React.useState('fa-key');

  function setError(message) {
    setErrorMessage(message);
    if (message) {
      setShake(Date.now());
    } else {
      setShake(false);
    }
  }

  function handleStatusChange(event) {
    handleChange(event);
    setNeedsAddress(event.target.value.trim() === 'Homeowner');
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (formData.email && formData.name && formData.phone && formData.unit && formData.status && (formData.address || !needsAddress)) {
      setSubmitIcon('fa-hourglass-half');
      post('/register', formData).then(() => {
        setSent(true);
        setError('');
        setSubmitIcon('nc-check-2');
      }).catch(() => {
        setError('Hmm, we seem to be having some issues processing your request. Please try again later.');
        setSubmitIcon('nc-simple-remove');
      });
    } else {
      setError('Please enter all required fields.');
    }
    setTimeout(() => {
      setSubmitIcon('nc-send');
    }, 3000);
  }

  return (
    <App title="Register" pageKey="register-page">
      <div className="main">
        <Container>
          <div className="section">
            <h2 className="title text-center">Member Registration</h2>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={{ size: 8, offset: 2 }}>
                  <Alert color="success" isOpen={hasSent}>
                    <Container>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={() => setSent(false)}
                      >
                        <Icon icon="nc-simple-remove" />
                      </button>
                      <span><Icon icon="nc-check-2"/> Thank you for registering! We'll be in touch with you soon.</span>
                    </Container>
                  </Alert>
                  <FormGroup>
                    <Label>
                      <h6>Name <span className="icon-danger">*</span></h6>
                    </Label>
                    <Input
                      name="name"
                      className="border-input"
                      placeholder="Full Name"
                      type="text"
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <h6>Email <span className="icon-danger">*</span></h6>
                    </Label>
                    <Input
                      name="email"
                      type="email"
                      className="border-input"
                      placeholder="Email"
                      onChange={handleChange}
                    />
                  </FormGroup>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>
                          <h6>Phone <span className="icon-danger">*</span></h6>
                        </Label>
                        <Input
                          name="phone"
                          className="border-input"
                          placeholder="Phone Number"
                          type="tel"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>
                          <h6>Home Number <span className="icon-danger">*</span></h6>
                        </Label>
                        <Input
                          name="unit"
                          className="border-input"
                          placeholder="Home Number (e.g. 2269)"
                          type="text"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup radioGroup="status">
                        <h6>Homeowner or Resident?</h6>
                        <span className="form-check-radio">
                          <Label check>
                            <Input
                              defaultValue="Resident"
                              name="status"
                              type="radio"
                              onChange={handleStatusChange}
                            />
                            <span className="form-check-sign" /> Resident{" "}
                          </Label>
                        </span>
                        <span className="form-check-radio">
                          <Label check>
                            <Input
                              defaultValue="Homeowner"
                              name="status"
                              type="radio"
                              onChange={handleStatusChange}
                            />
                            <span className="form-check-sign" /> Homeowner{" "}
                          </Label>
                        </span>
                        <span className="form-check-radio">
                          <Label check>
                            <Input
                              defaultChecked
                              defaultValue="Both"
                              name="status"
                              type="radio"
                              onChange={handleStatusChange}
                            />
                            <span className="form-check-sign" /> Both{" "}
                          </Label>
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup check>
                        <h6>Subscribe to emails?</h6>
                        <Label check>
                          <Input
                            defaultChecked
                            defaultValue="yes"
                            type="checkbox"
                            name="subscribe"
                            onChange={handleChange}
                          />
                          <span className="form-check-sign" /> Yes, add me to the community mailing lists
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>

                  {needsAddress && <FormGroup>
                    <Label>
                      <h6>Mailing Address <span className="icon-danger">*</span></h6>
                    </Label>
                    <Input
                      name="address"
                      type="text"
                      className="border-input"
                      placeholder="Mailing Address"
                      onChange={handleChange}
                    />
                  </FormGroup>}

                </Col>
              </Row>
              <div className="text-center">
                <SuperButton className="btn-fill" color="danger" size="lg" icon={submitIcon} shake={shake}>
                  Register
                </SuperButton>
                {errorMessage && <div className="text-center form-control-feedback">{errorMessage}</div>}
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </App>
  );
}
