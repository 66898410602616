/*eslint-disable*/
import React from "react";
import classnames from "classnames";

// reactstrap components
import { Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

// core components

const footerColors = {
  white: 'footer-white',
  black: 'footer-black',
  gray: 'footer-gray'
};

function GlobalFooter({ color, size }) {
  color = color ?? 'white';
  const className = classnames([
    'footer',
    size === 'big' && 'footer-big',
    footerColors[color] || footerColors.white
  ]);
  return (
    <>
      <footer className={className}>
        <Container>
          <Row>
            <nav className="footer-nav">
              <ul>
                <li>
                  <Link to="/" className="mr-1">Home</Link>
                </li>
                <li>
                  <Link to="/faq" className="mr-1">FAQ</Link>
                </li>
                <li>
                  <Link to="/documents" className="mr-1">Documents</Link>
                </li>
                <li>
                  <Link to="/board" className="mr-1">Board of Directors</Link>
                </li>
                <li>
                  <Link to="/contact" className="mr-1">Contact Us</Link>
                </li>
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright">
                © {new Date().getFullYear()} Soledad Westview Homeowners Association
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default GlobalFooter;
