import React from "react";
import App from 'components/App';
import { Container, Row, Col } from "reactstrap";
import ExternalLink from "components/UI/ExternalLink";
import Icon from "components/UI/Icon";
import InfoCard from "components/UI/InfoCard";
import { Link } from "react-router-dom";

export default function AboutPage() {
  return (
    <App title="About" pageKey="about-page" className="about-us">
      <div className="main">
        <Container>
          <div className="section">
            <h2 className="title">About Soledad Westview</h2>
            <div className="description">
              <p>The Soledad Westview community, established in 1988, is located in beautiful <ExternalLink href="https://pacificbeach.org/">Pacific Beach</ExternalLink> in <ExternalLink href="https://www.sandiego.org/">San Diego</ExternalLink>, California.</p>
              <p>Soledad Westview is a community of twelve homes and is perfectly located to enjoy all that San Diego has to offer. Nestled in the hills of Pacifica, we're out of the <em>hustle and bustle</em> of the city, but have easy access to all amenities and destinations.</p>
              <p>Enjoy the quirks and vibes of this quaint beach town or take a jaunt to world famous attractions in our backyard:</p>
              <ul>
                <li><ExternalLink href="https://zoo.sandiegozoo.org/">San Diego Zoo</ExternalLink> &amp; <ExternalLink href="https://sdzsafaripark.org/">Safari Park</ExternalLink></li>
                <li><ExternalLink href="https://seaworld.com/san-diego/">Seaworld</ExternalLink></li>
                <li><ExternalLink href="https://www.balboapark.org/">Balboa Park</ExternalLink></li>
                <li><ExternalLink href="https://oldtownsandiegoguide.com/">Old Town San Diego</ExternalLink></li>
                <li><ExternalLink href="https://www.belmontpark.com/">Belmont Park</ExternalLink></li>
                <li><ExternalLink href="https://www.midway.org/">USS Midway</ExternalLink> and <ExternalLink href="https://sandiegomuseumcouncil.org/museums/">other museums</ExternalLink></li>
                <li><ExternalLink href="https://aquarium.ucsd.edu/">Birch Aquarium</ExternalLink></li>
                <li><ExternalLink href="https://www.mlb.com/padres/ballpark">Petco Park</ExternalLink></li>
                <li><ExternalLink href="https://www.sandiego.org/explore/things-to-do/beaches-bays.aspx">picturesque beaches and bays</ExternalLink></li>
                <li><ExternalLink href="https://www.alltrails.com/us/california/san-diego">scenic hikes</ExternalLink></li>
                <li>and much more...</li>
              </ul>
            </div>
            <h3 className="title">Need more information?</h3>
            <Row>
              {[
                {
                  title: 'FAQ',
                  icon: 'fa-question',
                  description: 'Get answers to your Frequently Asked Questions',
                  to: '/faq',
                },
                {
                  title: 'Member Portal',
                  icon: 'nc-single-02',
                  description: 'Access the secure Members Portal for member resources',
                  to: '/members',
                },
                {
                  title: 'Board of Directors',
                  icon: 'fa-users',
                  description: 'Learn about the volunteers who maintain the HOA',
                  to: '/board',
                },
                {
                  title: 'Contact Us',
                  icon: 'nc-send',
                  description: 'Get in touch with us for all other inquiries',
                  to: '/contact',
                },
              ].map(({ title, icon, blurb, to }, i) => {
                return (
                  <Col md="3" key={i}>
                    <Link to={to}>
                      <InfoCard title={title} icon={<Icon icon={icon}/>} iconColor="icon-primary">
                        {blurb}
                      </InfoCard>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Container>
      </div>
    </App>
  );
}
