import React from "react";
import App from "components/App.js";
import Icon from 'components/UI/Icon';
import useFormChange from "utils/useFormChange";
import { post } from "utils/api";

// reactstrap components
import {
  Alert,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import SuperButton from "components/UI/SuperButton.js";

const changeTypes = {
  exteriorPaint: {
    label: 'Exterior Paint',
    hint: 'Changes to the Home body or trim color',
  },
  garage: {
    label: 'Garage Door',
    hint: 'Changes in color or style of the garage door'
  },
  solar: {
    label: 'Roof Mounted Solar',
    hint: 'Addition of roof-mounted solar panels',
  },
  electrical: {
    label: 'Exterior Electrical (visible)',
    hint: 'Changes to the Circuit Breaker Panel, addition of EV charging points, etc'
  },
  roof: {
    label: 'Roofing',
    hint: 'Changes to the visible roofing material (tile, etc)'
  },
  balcony: {
    label: 'Patio / Balcony',
    hint: 'Addition of shade/cover, change of railing, etc'
  },
  hardscape: {
    label: 'Hardscape',
    hint: 'Alterations to driveway, walkway, or retaining walls'
  },
  landscape: {
    label: 'Landscape',
    hint: 'Addition or removal or plants in Common Areas'
  },
  structure: {
    label: 'Structure',
    hint: 'Any other publicly visible alterations to the Home structure'
  },
  other: {
    label: 'Other',
    hint: 'Any other changes not covered by the above options'
  },
};

export default function ChangeRequestPage() {
  const [hasSent, setSent] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [shake, setShake] = React.useState(false);
  const [formData, handleChange] = useFormChange({
    'status': 'Both',
    'ack-acc': true,
    'ack-permits': true,
  });
  const [submitIcon, setSubmitIcon] = React.useState('fa-drafting-compass');

  function setError(message) {
    setErrorMessage(message);
    if (message) {
      setShake(Date.now());
    } else {
      setShake(false);
    }
  }

  function processFormData() {
    // validate
    const requiredFields = [
      'name',
      'email',
      'description',
      'ack-acc',
      'ack-permits',
    ];

    const hasMissing = requiredFields.some((key) => {
      return !formData[key];
    });

    if (hasMissing) {
      return;
    }

    const realFormData = new FormData();
    const changes = [];
    Object.entries(changeTypes).forEach(([id, { label }]) => {
      if (formData[`changes-${id}`]) {
        changes.push(label);
      }
    });
    realFormData.append('changes', changes.join(';') || 'Other');
    Object.entries(formData).forEach(([key, value]) => {
      if (key === 'files') {
        Array.from(value).forEach(file => {
          realFormData.append('files', file);
          realFormData.append('filenames', file.name);
        });
      } else if (!key.startsWith('changes-')) {
        realFormData.append(key, value);
      }
    });
    return realFormData;
  }

  function handleSubmit(event) {
    event.preventDefault();
    const data = processFormData();
    if (data) {
      setSubmitIcon('fa-hourglass-half');
      post('/changeRequest', data, { contentType: 'multipart/form-data' }).then(() => {
        setSent(true);
        setError('');
        setSubmitIcon('nc-check-2');
      }).catch(() => {
        setError('Hmm, we seem to be having some issues processing your request. Please try again later.');
        setSubmitIcon('nc-simple-remove');
      });
    } else {
      setError('Please enter all required fields.');
    }
    setTimeout(() => {
      setSubmitIcon('fa-drafting-compass');
    }, 3000);
  }

  return (
    <App title="Change Request" pageKey="change-request-page" restricted>
      <div className="main">
        <Container>
          <div className="section">
            <h2 className="title text-center">Change Request</h2>
            <div className="description">
              <p>This is the Soledad Westview HOA official process for requesting changes to Common Areas and/or unit Exteriors.</p>
            </div>
            <Alert color="success" isOpen={hasSent}>
              <Container>
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setSent(false)}
                >
                  <Icon icon="nc-simple-remove" />
                </button>
                <div className="message"><Icon icon="nc-check-2"/> Thank you for submitting your change request. We look forward to reviewing it and will be in touch soon.</div>
              </Container>
            </Alert>

            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md="6">
                  <h3>Your Information</h3>
                  <FormGroup>
                    <Label>
                      <h6>Name <span className="icon-danger">*</span></h6>
                    </Label>
                    <Input
                      name="name"
                      className="border-input"
                      placeholder="Full Name"
                      type="text"
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <h6>Email <span className="icon-danger">*</span></h6>
                    </Label>
                    <Input
                      name="email"
                      type="email"
                      className="border-input"
                      placeholder="Email"
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <h6>Phone</h6>
                    </Label>
                    <Input
                      name="phone"
                      className="border-input"
                      placeholder="Phone Number"
                      type="tel"
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <h6>Home Number <span className="icon-danger">*</span></h6>
                    </Label>
                    <Input
                      name="unit"
                      className="border-input"
                      placeholder="Home Number (e.g. 2269)"
                      type="text"
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup radioGroup="status">
                    <h6>Homeowner or Resident?</h6>
                    <span className="form-check-radio">
                      <Label check>
                        <Input
                          defaultValue="Resident"
                          name="status"
                          type="radio"
                          onChange={handleChange}
                        />
                        <span className="form-check-sign" /> Resident{" "}
                      </Label>
                    </span>
                    <span className="form-check-radio">
                      <Label check>
                        <Input
                          defaultValue="Homeowner"
                          name="status"
                          type="radio"
                          onChange={handleChange}
                        />
                        <span className="form-check-sign" /> Homeowner{" "}
                      </Label>
                    </span>
                    <span className="form-check-radio">
                      <Label check>
                        <Input
                          defaultChecked
                          defaultValue="Both"
                          name="status"
                          type="radio"
                          onChange={handleChange}
                        />
                        <span className="form-check-sign" /> Both{" "}
                      </Label>
                    </span>
                  </FormGroup>
                </Col>

                <Col md="6">
                  <h3>Proposed Improvement(s) <span className="icon-danger">*</span></h3>
                  <p>Please check all that apply</p>
                  {Object.entries(changeTypes).map(([name, { label, hint }], i) => {
                    return (
                      <FormGroup check key={i}>
                        <Label check>
                          <Input
                            defaultValue={label}
                            type="checkbox"
                            name={`changes-${name}`}
                            onChange={handleChange}
                          />
                          <span className="form-check-sign" /> {label} {hint && (
                            <>
                              <Icon icon="far fa-question-circle" id={`hint-${name}`}/>
                              <UncontrolledTooltip placement="right" target={`hint-${name}`}>
                                {hint}
                              </UncontrolledTooltip>
                            </>
                          )}
                        </Label>
                      </FormGroup>
                    );
                  })}
                </Col>
              </Row>

              <h3>Project Details</h3>
              <FormGroup>
                <Label>
                  <h6>Describe the Project <span className="icon-danger">*</span></h6>
                </Label>
                <Input
                  name="description"
                  placeholder="Please describe the project in as much detail as possible..."
                  type="textarea"
                  rows="4"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label>
                  <h6>Attachments</h6>
                </Label>
                <Input
                  multiple
                  type="file"
                  name="files"
                  onChange={handleChange} />
              </FormGroup>

              <div className="text-center">
                <SuperButton className="btn-fill" color="danger" size="lg" icon={submitIcon} shake={shake}>
                  Submit
                </SuperButton>
                {errorMessage && <div className="text-center form-control-feedback">{errorMessage}</div>}
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </App>
  );
}
