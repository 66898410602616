import React from "react";
import { Link } from "react-router-dom";
import Icon from "components/UI/Icon";

// reactstrap components
import { Card, CardBody, Container, Row, Col } from "reactstrap";

// core components
import App from "components/App";
import GlobalNavbar from "components/Navbars/GlobalNavbar";

function Error404() {
  return (
    <App
      globalNav={<GlobalNavbar transparent={true} />}
      globalFooter={false}
      pageKey="error-404"
    >
      <div
        className="background-img"
        style={{
          backgroundImage: `url(${require("assets/img/landings/sunset-lajolla.jpg").default})`,
        }}
      >
        <div className="filter" />
        <Container>
          <Row>
            <h1 className="title">
              404
              <br />
              <p className="error-msg">The page you requested could not be found</p>
            </h1>
          </Row>
          <div className="container-cards">
            <Row>
              <h5 className="discover-pages text-center">You can discover:</h5>
              <br />
              <br />
              <br />
            </Row>
            <Row>
              <Container>
                <Row>
                  <Col md="3">
                    <Link to="/faq">
                      <Card className="card-just-text">
                        <CardBody className="text-center">
                          <div className="card-icon">
                            <Icon icon="fa-question"/>
                          </div>
                          <p className="card-description">
                            FAQ
                          </p>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                  <Col md="3">
                    <Link to="/documents">
                      <Card className="card-just-text">
                        <CardBody className="text-center">
                          <div className="card-icon">
                            <Icon icon="nc-single-copy-04"/>
                          </div>
                          <p className="card-description">
                            Documents
                          </p>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                  <Col md="3">
                    <Link to="/members">
                      <Card className="card-just-text">
                        <CardBody className="text-center">
                          <div className="card-icon">
                            <Icon icon="nc-single-02"/>
                          </div>
                          <p className="card-description">
                            Members Portal
                          </p>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                  <Col md="3">
                    <Link to="/people">
                      <Card className="card-just-text">
                        <CardBody className="text-center">
                          <div className="card-icon">
                            <Icon icon="fa-users"/>
                          </div>
                          <p className="card-description">
                            People
                          </p>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </Row>
          </div>
        </Container>
      </div>
    </App>
  );
}

export default Error404;
