import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import { useState, useEffect } from 'react';
import Icon from './Icon';
import ExternalLink from './ExternalLink';

export default function SuperButton(props) {
  const { shake, linkTo, color, children, className, external, ...rest } = props;
  const [extraClassNames, setExtraClassNames] = useState([]);
  useEffect(() => {
    if (shake) {
      setExtraClassNames(['animated', 'shake']);
      const timeout = setTimeout(() => {
        setExtraClassNames(['animated']);
      }, 1000);
      return function cleanup() {
        clearTimeout(timeout);
      }
    }
  }, [shake]);

  const LinkWrapper = external ? ExternalLink : Link;

  const icon = props.icon && (<><Icon icon={props.icon}/>{" "}</>);
  const button = <Button color={color} className={classnames([className ?? 'btn-round'].concat(extraClassNames))} {...rest}>{icon}{children}</Button>;
  return linkTo ? <LinkWrapper to={linkTo}>{button}</LinkWrapper> : button;
}
