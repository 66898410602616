const officerPositions = {
  president: {
    title: 'President',
    description: 'The president shall be the chief executive officer of the Association. He shall preside at all meetings of the Association and of the Board. He shall have all of the general powers and duties which are usually vested in the office of the president of an association including, but not limited to, the power to appoint committees from among the Members from time to time as he may in his discretion decide is appropriate to assist in the conduct of the affairs of the Association. All disbursements of funds, monies, and other assets of the Association shall require the joint written signatures of the President and Treasurer, provided, however, that if the President or Treasure is not available the Secretary may sign in his place.',
  },
  vp: {
    title: 'Vice President',
    description: 'The Vice President shall take the place of the President and perform his duties whenever the President shall be absent or unable to act. If neither the President nor the Vice President is able to act, the Board shall appoint some other member of the Board to do so on an interim basis. The Vice President shall also perform such other duties as shall from time to time be imposed upon him by the board.',
  },
  cfo: {
    title: 'Chief Financial Officer',
    description: 'The Chief Financial Officer shall have responsibility for Association funds and securities and shall be responsible for keeping full and accurate accounts of all receipts and disbursements in books belonging to the Association. Such books shall be open to inspection by any Member of the Association. He shall be responsible for the deposit of all monies and other valuable effects in the name of and to the credit of the Association in such depositories as may from time to time be designated by the Board, and shall disburse the funds of the Association as may be ordered by the Board.',
  },
  secretary: {
    title: 'Secretary',
    description: 'The Secretary shall keep the minutes of all meetings of the Board and the minutes of all meetings of the Association; He shall have charge of such books and papers as the Board may direct; and he shall, in general, perform all the duties incident to the office of Secretary. The Secretary shall also be responsible for maintaining the membership register of the Association.',
  },
  propertyManager: {
    title: 'Property Manager',
    descriptions: 'TODO',
  }
};

export default officerPositions;
