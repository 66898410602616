import React from "react";
import App from "components/App.js";
import { Container, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import Icon from "components/UI/Icon";
import ExternalLink from "components/UI/ExternalLink";
import InfoCard from "components/UI/InfoCard";
import DetailsModal from "components/UI/DetailsModal";
import { v4 as uuid } from 'uuid';
import chunkArray from "utils/chunkArray";

const services = {
  housekeeping: {
    label: 'Housekeeping',
    icon: 'fa-hand-sparkles',
  },
  plumbing: {
    label: 'Plumbing',
    icon: 'fa-wrench',
  },
  stucco: {
    label: 'Stucco Repair',
    icon: 'fa-home',
  },
  contractor: {
    label: 'General Contracting',
    icon: 'fa-hammer',
  },
  exteriorPainting: {
    label: 'Exterior Painting',
    icon: 'fa-paint-roller',
  },
  interiorPainting: {
    label: 'Interior Painting',
    icon: 'fa-brush',
  },
  electrical: {
    label: 'Electrical',
    icon: 'fa-bolt',
  },
};

function tel(phone) {
  return `tel:+01-${phone.replace(/\D+/g, ' ').trim().replace(/\s+/g, '-')}`;
}

function vendorCard({ href, title, phone, email, services, reviews }) {
  const icon = (services?.length && <>{services.map(({ label, icon }, i) => {
    const id = `icon-${uuid()}`;
    return (
      <span key={i}>
        <Icon icon={icon} key={i} id={id}/>{" "}
        <UncontrolledTooltip placement="top" target={id}>
          {label}
        </UncontrolledTooltip>
      </span>
    );
  })}<br/></>);

  const description = (
    <>
      {phone && <>Phone: <a href={tel(phone)}>{phone}</a><br/></>}
      {email && <>Email: <a href={`mailto:${email}`}>{email}</a><br/></>}
      {href && <>Website: <ExternalLink href={href}>{href}</ExternalLink><br/></>}
    </>
  );

  return {
    title,
    icon,
    description,
    modal: {
      title,
      body: <>
        <h5>Contact</h5>
        {description}<br/>
        {services?.length && <>
          <h5>Services Provided</h5>
          <ul>
            {services.map(({ label, icon }, i) => {
              return (
                <li key={i}>
                  <Icon icon={icon}/> {label}
                </li>
              );
            })}
          </ul>
        </>}
        {reviews.length && <>
          <h5>Reviews</h5>
          {reviews.map(({ review, name }, i) => {
            return (
              <blockquote className="blockquote" key={i}>
                {review && <p>"{review}"</p>}
                <footer>- {name}</footer>
              </blockquote>
            );
          })}
        </>}
      </>
    }
  }
}

const resources = {
  utilities: [
    {
      href: 'https://www.sdge.com/',
      title: 'SDG&E',
      icon: <Icon icon="fa-plug"/>,
      description: 'Manage your gas and electricity service and bills',
    },
    {
      href: 'https://customerportal.sandiego.gov/portal/',
      title: 'MyWaterSD',
      icon: <Icon icon="fa-faucet"/>,
      description: 'Manage your water service and bills',
    },
    {
      href: 'https://www.sdttc.com/',
      title: 'Tax Collector',
      icon: <Icon icon="fa-coins"/>,
      description: 'Manage payments for property tax',
    },
    {
      href: process.env.REACT_APP_PROPERTY_MANAGEMENT_URL,
      title: process.env.REACT_APP_PROPERTY_MANAGEMENT,
      icon: <Icon icon="fa-file-invoice"/>,
      description: 'Manage your HOA dues and billing',
    },
  ],
  publicSafety: [
    {
      title: 'Fire Department',
      icon: <Icon icon="fa-fire-extinguisher"/>,
      description: (<>
        Phone: <a href="tel:+01-619-533-4300">(619) 533-4300</a>
        <br/>
        Email: <a href="mailto:sdfd@sandiego.gov">sdfd@sandiego.gov</a>
      </>),
    },
    {
      title: 'Police Department',
      icon: <Icon icon="fa-gavel"/>,
      description: (<>
        Phone: <a href="tel:+01-858-484-3154">(858) 484-3154</a>
      </>),
    },
    {
      href: 'https://www.sandiego.gov/get-it-done',
      title: 'Get it Done',
      icon: <Icon icon="fa-check-circle"/>,
      description: 'Report non-emergency public issues to the city',
    },
  ],
  vendors: [
    {
      title: 'Elvira Rojas',
      phone: '(619) 994-5894',
      services: [services.housekeeping],
      reviews: [
        {
          review: 'Elvira is great! She’s been cleaning our place even before we lived here. She does a great job but the only issue is that she often times reschedules - this happens very often and it’s annoying but we tried another and she didn’t do as nice of a job.',
          name: 'Jeremy Boatman',
        },
      ],
    },
    {
      title: 'R.E. Glenn Plumbing',
      phone: '(858) 652-0001',
      services: [services.plumbing],
      reviews: [
        {
          review: 'Russell and his employee did a great job for us after our water leak last year. Nice guys and reasonable prices.',
          name: 'Corbin Donnelley',
        },
      ],
    },
    {
      title: 'G Brothers Plumbing',
      phone: '(619) 366-3301',
      services: [services.plumbing],
      reviews: [
        {
          review: 'Omar and his brother Estevan were Patti’s plumbers and we have used them several times. Great guys and very reasonable.',
          name: 'Mark and Catey',
        },
      ],
    },
    {
      title: 'Gerardo Saucedo',
      phone: '(760) 224-6180',
      email: 'g73saucedo@gmail.com',
      services: [services.stucco, services.exteriorPainting],
      reviews: [
        {
          name: 'Corbin Donnelley',
        },
        {
          name: 'Katey Ladwig',
        },
      ],
    },
    {
      title: 'Savell Construction',
      phone: '(619) 972-5700',
      // TODO: handle multiple services
      services: [services.contractor, services.plumbing, services.electrical],
      reviews: [
        {
          review: 'Bill Savell does any remodel or plumbing or electrical...etc. very sufficient guys.',
          name: 'Misa',
        },
      ],
    },
  ].map(vendorCard),
};

function InfoCards({ cards, perRow = 4, iconColor: defaultIconColor }) {
  const [modal, setModal] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);
  function updateModal(modal) {
    setModal(modal);
    setOpen(!!modal);
  }
  const chunks = chunkArray(cards, perRow);
  return (
    <>
      <DetailsModal title={modal?.title} body={modal?.body} isOpen={isOpen} onClose={() => {
        updateModal(null);
      }}/>
      {chunks.map((chunk, i) => {
        return (
          <Row key={i}>
            {chunk.map(({ title, icon, iconColor, href, description, modal }, i) => {
              const infoCard = (
                <InfoCard title={title} icon={icon} iconColor={iconColor || defaultIconColor}>
                  {description}
                  {modal && <Button className="btn-link" onClick={() => { updateModal(modal); }}>Learn More</Button>}
                </InfoCard>
              );
              const colSize = Math.min(Math.floor(12 / chunk.length), 12 / perRow);
              const wrappedInfoCard =
                (href && <ExternalLink href={href}>{infoCard}</ExternalLink>) ||
                (modal && <div onClick={() => updateModal(modal)}>{infoCard}</div>);
              return (
                <Col md={colSize} key={i}>
                  {wrappedInfoCard || infoCard}
                </Col>
              );
            })}
          </Row>
        )
      })}
    </>
  );
}



export default function ResourcesPage() {
  return (
    <App title="Resources" pageKey="resources-page" restricted>
      <div className="main">
        <Container>
          <div className="section">
            <h2 className="title">Resources</h2>
            <h3 id="utilites" className="title">Utilities &amp; Billing</h3>
            <InfoCards cards={resources.utilities} iconColor="icon-primary"/>

            <h3 id="public-safety" className="title">Public Safety</h3>
            <InfoCards cards={resources.publicSafety} perRow="3" iconColor="icon-danger"/>

            <h3 id="vendors" className="title">Recommended Vendors</h3>
            <InfoCards cards={resources.vendors} perRow="3" iconColor="icon-success"/>
          </div>
        </Container>
      </div>
    </App>
  );
}
