import React from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import { authenticate, useProtection } from "components/Restricted";
import useFormChange from "utils/useFormChange";

// reactstrap components
import {
  Card,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import App from 'components/App.js';
import SuperButton from "components/UI/SuperButton";
import Icon from "components/UI/Icon";
import ForgotPasswordModal from 'components/ForgotPasswordModal';

function LoginPage(props) {
  const { action } = useParams();
  const [ authenticated, setAuthenticated ] = useProtection();
  const [forgotPassword, setForgotPassword] = React.useState(action === 'forgot');
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [shake, setShake] = React.useState(false);
  const [formData, handleChange] = useFormChange();

  if (authenticated) {
    return <Redirect to={props?.location?.state?.referrer || '/'} />
  }

  function setError(message) {
    setErrorMessage(message);
    if (message) {
      setShake(Date.now());
    } else {
      setShake(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    const { username, password } = formData;
    const required = [
      !username && 'Username',
      !password && 'Password'
    ].filter(Boolean);
    if (required.length) {
      setError(`${required.join(' and ')} ${required.length > 1 ? 'are' : 'is'} required.`);
    } else {
      if (authenticate(formData)) {
        setAuthenticated(true);
        setError(false);
      } else {
        setError("Sorry, that's not correct.");
      }
    }
  }

  return (
    <App title="Login" pageKey="login-page" className="full-screen" globalFooter={false}>
      <ForgotPasswordModal isOpen={forgotPassword} onClose={() => {
        setForgotPassword(false);
      }} />
      <div className="wrapper">
        <div
          className="page-header"
          style={{
            backgroundImage: `url(${require("assets/img/landings/sunset-pb.jpg").default})`,
          }}
        >
          <div className="filter" />
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="4" md="6" sm="6">
                <Card className="card-register">
                  <CardTitle tag="h3">Welcome</CardTitle>
                  <Form className="register-form" onSubmit={handleSubmit}>
                    <FormGroup>
                      <Label>Username</Label>
                      <Input
                        name="username"
                        className="no-border"
                        placeholder="Username"
                        type="text"
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Password</Label>
                      <Input
                        name="password"
                        className="no-border"
                        placeholder="Password"
                        type="password"
                        onChange={handleChange}
                      />
                    </FormGroup>

                    <SuperButton block className="btn-round" color="danger" shake={shake}>
                      <Icon icon="fa-key" /> Log In
                    </SuperButton>
                    {errorMessage && <div className="text-center form-control-feedback">{errorMessage}</div>}
                  </Form>
                  <div className="forgot">
                    <br/>
                    <Link to="/register" className="text-white">Need an account?</Link>
                    <br/>
                    <Link to="/login/forgot" className="text-white" onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setForgotPassword(true);
                    }}>Forgot password?</Link>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </App>
  );
}

export default LoginPage;
