import { Modal, ModalBody } from "reactstrap";

export default function DetailsModal({ isOpen, onClose, title, body, size = "md" }) {
  return (
    <Modal isOpen={isOpen} toggle={onClose} size={size}>
      <div className="modal-header">
        <button
          className="close"
          type="button"
          onClick={onClose}
          title="Close"
        >
          <span>×</span>
        </button>
        <h5
          className="modal-title"
        >
          {title}
        </h5>
      </div>
      <ModalBody>
        {body}
      </ModalBody>
    </Modal>
  );
}
