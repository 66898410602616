import { Route } from 'react-router-dom';
import Restricted from "components/Restricted";

export default function ExternalRedirect({ to, restricted, ...routeProps }) {
  const redirectRoute = (
    <Route
      {...routeProps}
      render={() => {
        window.location.replace(to);
        return null;
      }}
    />
  );
  return restricted ? <Restricted>{redirectRoute}</Restricted> : redirectRoute;
}
