import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Col, Container, Row } from 'reactstrap';

import App from 'components/App';
import ExternalRedirect from "components/ExternalRedirect";
import Error404 from "views/ErrorPages/Error404";
import ExternalLink from "components/UI/ExternalLink";
import Icon from "components/UI/Icon";
// import DocumentModal from "components/DocumentModal";

import { isAuthenticated } from "components/Restricted";

const documents = [
  {
    id: 'handbook',
    label: 'Member Handbook',
    description: 'Useful information to get you started and a summarized version of the legal jargon.',
    icon: 'nc-book-bookmark'
  },
  {
    id: 'CCRs',
    label: 'CC&Rs',
    title: 'Covenants, Conditions & Restrictions',
    description: 'The rules, regulations, and limitations of what you can do with your property.',
    icon: 'nc-single-copy-04',
    file: require('assets/documents/CCRs.pdf').default
  },
  {
    id: 'aio',
    label: 'Articles of Incorporation',
    description: 'Formal documents filed with the state of California to legally document the creation of the HOA.',
    icon: 'nc-bank',
    file: require('assets/documents/ArticlesOfIncorporation.pdf').default
  },
  {
    id: 'bylaws',
    label: 'Bylaws',
    description: 'Information and regulations on the operations of the Association.',
    icon: 'nc-briefcase-24',
    file: require('assets/documents/Bylaws.pdf').default
  },
  {
    id: 'meetings',
    to: '/members/meetings',
    restricted: true,
    label: 'Meeting Minutes',
    description: 'A collection of notes from prior Board and Member meetings.',
    icon: 'nc-paper',
  },
  {
    id: 'delinquencyPolicy',
    label: 'Delinquency Policy',
    description: 'The official policy for handling late and overdue HOA dues payments.',
    icon: 'nc-time-alarm',
    file: require('assets/documents/DelinquencyPolicy.pdf').default
  },
  {
    id: 'condoPlan',
    label: 'Condominium Plan',
    description: 'The original Condominium Plan',
    icon: 'nc-layout-11',
    file: require('assets/documents/CondominiumPlan.pdf').default
  },
];

export default function DocumentsPage() {
  const { id } = useParams();
  const { hash } = useLocation();

  if (id) {
    const doc = documents.find(doc => id === doc.id);
    return doc?.file ? <ExternalRedirect to={[doc.file, hash].filter(Boolean).join('')} restricted/> : <Error404/>;
  }

  return (
    <>
      {/* <DocumentModal id={id} file={doc?.file} title={doc?.title}/> */}
      <App title="Documents" pageKey="documents-page" className="about-us" restricted>
        <div className="main">
          <div className="section">
            <Container>
              <h3 className="title-uppercase">HOA Governing Documents</h3>
              <Row>
                <Col md="6">
                  <p>Several legal and policy documents govern the homeowners and residents who live in Soledad Westview. All homeowners and residents are legally bound to abide by the rules set forth in these documents.</p>
                  <p>The <ExternalLink to="/documents/aoi">Articles of Incorporation</ExternalLink> and the <ExternalLink to="/documents/bylaws">Bylaws</ExternalLink> of the Home Owners Association (HOA) were established on August 10, 1988 to create the HOA as a non-profit corporation. These documents provide the framework for the organization and powers of the HOA. They also provide for the election of the <Link to="/board">Board of Directors</Link>, and the powers of the members of the HOA, which are the homeowners and residents. The HOA Board of Directors is empowered by these documents to enforce the Covenants, subject to the laws of California.</p>
                  <p>The <ExternalLink to="/documents/CCRs">Covenants, Conditions and Restrictions (or CC&amp;Rs)</ExternalLink> constitute an agreement between the HOA and purchasers of homes in Soledad Westview of how the properties may be used and the agreed-upon practices and procedures for living in a covenant neighborhood.</p>
                  <p>The Covenants can sometimes be difficult to understand, so an interpretation of some of the Covenants, as practiced, was developed by the Board and published as the <ExternalLink to="/documents/handbook">Member Handbook</ExternalLink>. This handbook is updated periodically.</p>
                  <p>Collectively, these four documents are the strength of Soledad Westview, and they provide for a stable, well-run community that the homeowners can take pride in.</p>
                </Col>
                <Col md="6">
                  {documents.map(({ id, to, label, description, icon, restricted }, i) => {
                    if (restricted && !isAuthenticated()) {
                      return null;
                    }
                    to = to ?? `/documents/${id}`;
                    return (
                      <ExternalLink to={to} key={i}>
                        <div className="info info-compact info-horizontal">
                          <div className="icon icon-primary">
                            <Icon icon={icon} />
                          </div>
                          <div className="description">
                            <h5 className="info-title">{label}</h5>
                            {description}
                          </div>
                        </div>
                      </ExternalLink>
                    );
                  })}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </App>
    </>
  );
}
