import { getAuthToken } from 'components/Restricted';

const isDev = process.env.REACT_APP_DEV_API;
const apiServer = process.env[isDev ? 'REACT_APP_API_SERVER_DEV' : 'REACT_APP_API_SERVER'];

const mimeTypes = {
  json: 'application/json',
  formData: 'multipart/form-data',
  urlEncoded: 'application/x-www-form-urlencoded',
};

export async function post(endpoint, data, { contentType } = {}) {
  const authToken = getAuthToken();
  const isFormData = data instanceof FormData || (contentType === mimeTypes.formData);

  contentType = contentType ?? (isFormData ? mimeTypes.formData : mimeTypes.json);

  let body;
  if (contentType === mimeTypes.json) {
    body = JSON.stringify({
      ...data,
      authToken
    });
  } else if (contentType === mimeTypes.formData) {
    data.append('authToken', authToken);
    body = data;
  } else {
    throw new Error('unsupported mime-type');
  }

  const headers = isFormData ? null : {
    headers: {
      'Content-Type': contentType,
    },
  };

  const response = await fetch(`${apiServer}${endpoint}`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    redirect: 'follow',
    referrerPolicy: 'strict-origin-when-cross-origin',
    body,
    ...headers
  });

  if (response.ok) {
    const json = await response.json();
    if (json.ok === false) {
      throw new Error(json.error || 'Unknown error');
    }
    return json;
  } else {
    throw new Error(response.statusText || response.status);
  }
}
