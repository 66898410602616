import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch, useLocation } from "react-router-dom";
import ExternalRedirect from "components/ExternalRedirect";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

// pages
import HomePage from "views/HomePage.js";
import LoginPage from "views/LoginPage.js";
import ContactPage from "views/ContactPage.js";
import DocumentsPage from "views/DocumentsPage";
import AboutPage from "views/AboutPage";
import RegisterPage from "views/RegisterPage.js";
import PeoplePage from "views/PeoplePage";
import FAQPage from "views/FAQPage";
import HandbookPage from 'views/HandbookPage.js';

// Member Pages
import MemberPortal from 'views/MemberPortal.js';
import EventsPage from 'views/Members/EventsPage.js';
import ProjectsPage from 'views/Members/ProjectsPage.js';
import ResourcesPage from 'views/Members/ResourcesPage.js';
import IssuePage from 'views/Members/IssuePage.js';
import ParkingPermitPage from 'views/Members/ParkingPermitPage.js';
import ChangeRequestPage from 'views/Members/ChangeRequestPage';

// Error pages
import Error404 from 'views/ErrorPages/Error404.js';
// import UnderConstruction from 'views/ErrorPages/UnderConstruction.js';

// others
import ExampleRoutes from "views/examples/ExampleRoutes";


function ScrollManager() {
  const { pathname, hash } = useLocation();

  function jumpTo($jumpTo) {
    $jumpTo.scrollIntoView({ behavior: 'smooth' });
  }

  React.useEffect(() => {
    function jumpToListener(event) {
      const { target } = event;
      if (target?.tagName === 'A') {
        const id = target?.href?.split('#')[1];
        const $jumpTo = id && document.getElementById(id);
        if ($jumpTo) {
          event.preventDefault();
          jumpTo($jumpTo);
        }
      }
    }
    document.body.addEventListener('click', jumpToListener);

    return function cleanupJumpToListener() {
      document.body.removeEventListener('click', jumpToListener);
    };
  }, []);

  React.useEffect(() => {
    const $target = hash && document.querySelector(hash);
    if ($target) {
      const timer = setTimeout(() => {
        jumpTo($target);
      }, 10);

      return function cleanup() {
        clearTimeout(timer);
      };
    }

    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, [pathname, hash]);

  return null;
}

ReactDOM.render(
  <BrowserRouter>
    <ScrollManager />
    <Switch>
      <Route path="/" exact component={HomePage} />
      <Route path="/login/:action?" component={LoginPage} />
      <Route path="/contact" component={ContactPage} />
      <Route path="/people" component={PeoplePage} />
      <Route path="/about" component={AboutPage} />
      <Route path="/faq" component={FAQPage} />
      <Route path="/board" render={() => <Redirect to="/people#board" />} />

      {/* Members */}
      {process.env.REACT_APP_FEATURE_MEMBERS_EVENTS && <Route path="/members/events" component={EventsPage} />}
      {process.env.REACT_APP_FEATURE_MEMBERS_PROJECTS && <Route path="/members/projects" component={ProjectsPage} />}
      {process.env.REACT_APP_FEATURE_MEMBERS_RESOURCES && <Route path="/members/resources" component={ResourcesPage} />}
      {process.env.REACT_APP_FEATURE_MEMBERS_REPORT_ISSUE && <Route path="/members/issue" component={IssuePage} />}
      {process.env.REACT_APP_FEATURE_MEMBERS_PARKING_PERMIT && <Route path="/members/parking" component={ParkingPermitPage} />}
      {process.env.REACT_APP_FEATURE_MEMBERS_MEETINGS && <Route path="/members/meetings" render={() => <ExternalRedirect to={process.env.REACT_APP_MEETING_MINUTES} restricted />} />}
      {process.env.REACT_APP_FEATURE_MEMBERS_CHAT && <Route path="/members/chat" render={() => <ExternalRedirect to={process.env.REACT_APP_CHAT_URL} />} />}
      {process.env.REACT_APP_FEATURE_MEMBERS_VENDORS && <Route path="/members/vendors" render={() => <Redirect to="/members/resources#vendors" />} />}
      {process.env.REACT_APP_FEATURE_MEMBERS_CHANGE_REQUEST && <Route path="/members/change-request" component={ChangeRequestPage} />}

      <Route path="/members" exact component={MemberPortal} />

      <Route path="/documents/handbook" component={HandbookPage} />
      <Route path="/documents/:id?" component={DocumentsPage} />
      <Route path="/register" component={RegisterPage} />

      {process.env.NODE_ENV !== 'production' && <ExampleRoutes/>}

      {/* Not Found 404 */}
      <Route component={Error404} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
