import { useState } from "react";

export default function useFormChange(initialFormData = {}) {
  const [formData, updateFormData] = useState(initialFormData);
  function handleChange({ target }) {
    const { files, name, type } = target;
    let value;
    if (type === 'checkbox') {
      value = target.checked;
    } else if (files) {
      value = files;
    } else {
      value = target.value.trim();
    }
    updateFormData({
      ...formData,
      [name]: value,
    });
  };

  return [formData, handleChange];
}
