import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import cryptoJS from "crypto-js";


const utf8 = cryptoJS.enc.Utf8;

// encrypt the given key with a salt
function encrypt(key, salt) {
  const algo = cryptoJS.algo.SHA256.create();
  algo.update(key, utf8);
  algo.update(cryptoJS.SHA256(salt), utf8);
  return algo.finalize().toString(cryptoJS.enc.Base64);
}

export function getAuthToken() {
  return localStorage.getItem('authToken');
}

export function isAuthenticated(token = getAuthToken()) {
    // encrypt our token with the global salt
    const actualToken = token && encrypt(token, process.env.REACT_APP_AUTH_SALT) ;
    // and compare to the expected token
    return actualToken === process.env.REACT_APP_AUTH_TOKEN;
}

export function authenticate({ authToken, username, password }) {
  // if we're already authenticated, no need to check
  if (isAuthenticated()) {
    return true;
  }
  // if we don't have a token or username/password, we can't auth
  if (!authToken && !(username && password)) {
    return false;
  }

  // use the provided token or encrypt the username with the password as a salt
  const token = authToken || encrypt(username.toLowerCase(), password);
  if (isAuthenticated(token)) {
    localStorage.setItem('authToken', token);
    return true;
  }
  return false;
}

export function logout() {
  localStorage.setItem('authToken', '');
}

export function getShareableURL(location) {
  if (isAuthenticated()) {
    const url = new URL(document.location.href);
    url.pathname = location.pathname;
    url.search = location.search;
    url.hash = location.hash;
    url.searchParams.set('authToken', cryptoJS.AES.encrypt(getAuthToken(), location.pathname));
    return url.href;
  }
}

export function useProtection(authToken) {
  const [authenticated, setAuthenticated] = React.useState(authenticate({ authToken }));
  React.useEffect(() => {
    function updateAuthenticated() {
      setAuthenticated(isAuthenticated());
    }
    window.addEventListener('storage', updateAuthenticated);

    return function cleanup() {
      window.removeEventListener('storage', updateAuthenticated);
    };
  }, []);

  return [ authenticated, setAuthenticated ];
}

export default withRouter(function Restricted({ children, location }) {
  // authToken from query param - this is encrypted with the target route path
  const encryptedAuthToken = new URLSearchParams(location.search).get('authToken');
  const authToken = encryptedAuthToken && cryptoJS.AES.decrypt(encryptedAuthToken, location.pathname).toString(utf8);

  const [ authenticated ] = useProtection(authToken);

  if (authenticated) {
    if (localStorage.getItem('debug')) {
      const shareableURL = getShareableURL(location);
      console.log('[Shareable URL]', shareableURL);
    }
    return children;
  }

  return (
    <Redirect to={{
      pathname: '/login',
      state: { referrer: location }
    }} />
  );
});
