import { Link } from "react-router-dom";
import ExternalLink from "components/UI/ExternalLink";

export default [
  process.env.REACT_APP_FEATURE_MEMBERS_CHAT && {
    icon: 'nc-chat-33',
    label: 'Discuss',
    link: { to: '/members/chat', tag: ExternalLink },
    description: (
      <>
        Join us in the Community Discussion to keep in touch with the neighbors and get up-to-date announcements.
        <br/><br/>
        Need an invite? Use the registration link below.
      </>
    ),
  },
  process.env.REACT_APP_FEATURE_MEMBERS_EVENTS && {
    icon: 'nc-calendar-60',
    label: 'Events',
    link: { to: '/members/events' },
    description: 'View our calendar of upcoming events. This includes important dates and any community events.',
  },
  process.env.REACT_APP_FEATURE_MEMBERS_PROJECTS && {
    icon: 'nc-bulb-63',
    label: 'Projects',
    link: { to: '/members/projects' },
    description: 'Stay in the know. Get information on current and upcoming projects.',
  },
  process.env.REACT_APP_FEATURE_MEMBERS_RESOURCES && {
    icon: 'nc-settings',
    label: 'Resources',
    link: { to: '/members/resources' },
    description: 'Get information about utilities, City & County services, and community recommended providers.',
  },
  process.env.REACT_APP_FEATURE_MEMBERS_REGISTER && {
    icon: 'nc-badge',
    label: 'Register',
    link: { to: '/register' },
    description: 'Submit your contact information to the HOA so you get important announcements and access to other member resources.',
  },
  process.env.REACT_APP_FEATURE_MEMBERS_HANDBOOK && {
    icon: 'nc-book-bookmark',
    label: 'Member Handbook',
    link: { to: '/documents/handbook' },
    description: 'Useful information to get you started and a summarized version of the legal jargon.',
  },
  process.env.REACT_APP_FEATURE_DOCUMENTS && {
    icon: 'nc-single-copy-04',
    label: 'Documents',
    link: { to: '/documents' },
    description: 'The HOA governing documents. This includes Community Rules, Articles of Incorporation, Conditions & Restrictions, Bylaws, and more.',
  },
  process.env.REACT_APP_FEATURE_MEMBERS_MEETINGS && {
    icon: 'nc-paper',
    label: 'Meeting Minutes',
    link: { to: '/members/meetings', tag: ExternalLink },
    description: 'A collection of notes from prior Board and Member meetings.',
  },
  process.env.REACT_APP_FEATURE_MEMBERS_REPORT_ISSUE && {
    icon: 'nc-tag-content',
    label: 'Report Issues',
    link: { to: '/members/issue' },
    description: 'Submit an issue, complaint, or violation report.',
  },
  process.env.REACT_APP_FEATURE_MEMBERS_CHANGE_REQUEST && {
    icon: 'nc-ruler-pencil',
    label: 'Change Request',
    link: { to: '/members/change-request' },
    description: 'Submit a Change Request for Architectural review.',
  },
  process.env.REACT_APP_FEATURE_MEMBERS_PARKING_PERMIT && {
    icon: 'nc-bus-front-12',
    label: 'Parking Permit',
    link: { to: '/members/parking' },
    description: 'Request a new or replacement Parking Permit.',
  },
].filter(Boolean).map((item) => {
  item.link.tag = item.link.tag ?? Link;
  return item;
}, []);
