import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components
import SuperButton from "components/UI/SuperButton";

function WelcomeHeader() {
  return (
    <>
      <div
        className="page-header"
        style={{
          backgroundImage: `url(${require("assets/img/landings/sunset-pb.jpg").default})`,
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto">
              <h1 className="title">Soledad Westview</h1>
              <h3 className="description">
                community starts here
              </h3>
              <br />
              <SuperButton
                linkTo="/faq"
                className="btn-round mr-1"
                color="neutral"
                icon="fa-question"
              >
                FAQ
              </SuperButton>
              <SuperButton
                linkTo="/documents"
                className="btn-round mr-1"
                color="neutral"
                icon="nc-single-copy-04"
              >
                Documents
              </SuperButton>
              <SuperButton
                linkTo="/members"
                className="btn-round mr-1"
                color="danger"
                icon="nc-single-02"
              >
                Member Portal
              </SuperButton>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default WelcomeHeader;
