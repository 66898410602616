import React from "react";
import App from "components/App.js";
import { Badge, Card, CardBody, CardFooter, CardTitle, Col, Container, Row } from "reactstrap";
import chunkArray from "utils/chunkArray";
import currencyFormatter from "utils/currencyFormatter";
import Icon from "components/UI/Icon";
import DetailsModal from "components/UI/DetailsModal";

const categories = {
  maintenance: {
    label: 'Maintenance',
    icon: 'nc-umbrella-13',
    color: 'default',
  },
  emergency: {
    label: 'Emergency',
    icon: 'fa-exclamation',
    color: 'danger',
  },
  landscaping: {
    label: 'Landscaping',
    icon: 'fa-seedling',
    color: 'success',
  },
};

const status = {
  planning: {
    label: 'Planning',
    icon: 'nc-bulb-63',
    color: 'default',
  },
  onHold: {
    label: 'On Hold',
    icon: 'nc-button-pause',
    color: 'warning',
  },
  inProgress: {
    label: 'In Progress',
    icon: 'nc-button-play',
    color: 'info',
  },
  upcoming: {
    label: 'Upcoming',
    icon: 'fa-clock-o',
    color: 'primary',
  },
  completed: {
    label: 'Completed',
    icon: 'nc-check-2',
    color: 'success',
  },
};

const projects = [
  {
    title: 'Backflow Prevention',
    status: status.planning,
    categories: [categories.maintenance],
    img: {
      src: require("assets/img/projects/backflow.jpg").default,
    },
    description: 'Our backflow preventer is currently not in compliance with City of San Diego regulations. The backflow needs to be tested and we will need to dig up and encase the plumbing lines from the backflow to the meter.'
  },
  {
    title: 'Palm Trimming',
    status: status.completed,
    categories: [categories.landscaping, categories.emergency],
    cost: [1450 + 489],
    date: '10/6/2021',
    img: {
      alt: 'Palm Tree Trimming',
      src: require("assets/img/projects/palm-trimming.jpg").default,
    },
    description: [
      'Pruning and trimming our palm trees keeps them healthy and prevents damage from falling palm fronds or seed pods.',
      'Unfortunately, one of the palms (between 2281 and 2285) was determined to be in poor health and posed a safety risk. This palm was removed.',
    ]
  },
];

function getStatus(stat) {
  return {
    isCompleted: stat === status.completed,
    ...(stat || status.planning),
  };
}

function range(data, preprocess) {
  preprocess = preprocess ?? function(i) { return i; }
  return Array.isArray(data) ? data.map(preprocess).join(' - ') : (data ?? 'TBD');
}

function ProjectCards({ projects, perRow = 3 }) {
  const [modal, setModal] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);
  function updateModal(modal) {
    setModal(modal);
    setOpen(!!modal);
  }

  const chunks = chunkArray(projects, perRow);
  return (
    <>
      <DetailsModal title={modal?.title} body={modal?.body} isOpen={isOpen} onClose={() => {
        updateModal(null);
      }}/>
      {chunks.map((chunk, i) => {
        return (
          <Row key={i}>
            {chunk.map(({ title, status, cost, img, description, date, categories }, i) => {
              status = getStatus(status);
              const dates = date ? [].concat(date) : [];
              const [startDate, endDate] = dates;
              const dateRange = range(date);
              const costRange = range(cost, currencyFormatter.format);
              const datePrefix = status.isCompleted ? '' : 'Target';
              const isDescriptionArray = Array.isArray(description);
              const modalDescription = isDescriptionArray && description.map((text, i) => {
                return <p key={i}>{text}</p>;
              });
              const modal = {
                title,
                body: (
                  <>
                    <h5>Project Description</h5>
                    {modalDescription || description}
                    <hr/>
                    {dateRange && <h5>Project Dates</h5>}
                    {startDate && endDate ? (
                      <>
                        {<>{datePrefix} Start Date: {startDate}<br/></>}
                        {<>{datePrefix} End Date: {startDate}<hr/></>}
                      </>
                    ) : (
                      <>
                        {dateRange && <>{datePrefix} Date: {dateRange}<hr/></>}
                      </>
                    )}

                    {costRange && <h5>Project Cost</h5>}
                    {costRange && <>{status.isCompleted ? 'Total' : 'Estimated'} Project Cost: {costRange}<hr/></>}

                    <h5>Project Status</h5>
                    <Icon icon={status.icon}/> {status.label}
                  </>
                ),
              }
              return (
                <Col md="4" key={i}>
                  <a href="#details" onClick={(event) => {
                    event.preventDefault();
                    updateModal(modal);
                  }}>
                    <Card className="card-blog">
                      {img && (
                        <div className="card-image">
                          <img className="img img-raised" alt={img.alt || title} src={img.src}/>
                        </div>
                      )}
                      <CardBody>
                        {categories?.map(({ label, color, icon }, i) => {
                          return (
                            <Badge className="mr-1" color={color} key={i} pill>
                              <Icon icon={icon} /> {label}
                            </Badge>
                          );
                        })}
                        <CardTitle tag="h5">{title}</CardTitle>
                        <p className="card-description">
                          {isDescriptionArray ? description[0] : description}
                        </p>
                        <hr />
                        <CardFooter>
                          <div className="author">
                            <strong className={`title-uppercase stats text-${status.color}`}><Icon icon={status.icon}/> {status.label}</strong>
                          </div>
                          <div className="stats">
                            {costRange && <><Icon icon="fa-file-invoice-dollar"/> {costRange}</>}
                            {dateRange && <><Icon icon="far fa-calendar-alt" /> {dateRange}</>}
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </a>
                </Col>
              );
            })}
          </Row>
        );
      })}
    </>
  );
}

export default function ProjectsPage() {
  return (
    <App title="Projects" pageKey="projects-page" restricted>
      <div className="main">
        <div className="section section-gray">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <h2 className="title">Projects</h2>
                <ProjectCards projects={projects}/>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </App>
  );
}
