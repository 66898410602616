import React from "react";
import App from "components/App.js";
import Icon from 'components/UI/Icon';
import useFormChange from "utils/useFormChange";
import { post } from "utils/api";

// reactstrap components
import {
  Alert,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";

// core components
import SuperButton from "components/UI/SuperButton.js";
import MapWrapper from "components/MapWrapper.js";

function ContactPage() {
  const [hasSent, setSent] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [shake, setShake] = React.useState(false);
  const [formData, handleChange] = useFormChange();
  const [submitIcon, setSubmitIcon] = React.useState('nc-send');

  function setError(message) {
    setErrorMessage(message);
    if (message) {
      setShake(Date.now());
    } else {
      setShake(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (formData.email && formData.name && formData.subject && formData.message) {
      setSubmitIcon('fa-hourglass-half');
      post('/contact', formData).then(() => {
        setSent(true);
        setError('');
        setSubmitIcon('nc-check-2');
      }).catch(() => {
        setError('Our inbox seems to be having some problems. Please try again later.');
        setSubmitIcon('nc-simple-remove')
      });
    } else {
      setError('All fields are required.');
    }
    setTimeout(() => {
      setSubmitIcon('nc-send');
    }, 3000);
  }

  return (
    <App title="Contact" pageKey="contact-page">
      <div className="wrapper">
        <div className="section section-gray">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">Get in touch with us</h2>
                <Alert color="success" isOpen={hasSent}>
                  <Container>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                      onClick={() => setSent(false)}
                    >
                      <Icon icon="nc-simple-remove" />
                    </button>
                    <span><Icon icon="nc-check-2"/> Thank you for reaching out, we will be in touch with you soon.</span>
                  </Container>
                </Alert>
                <Form className="contact-form" onSubmit={handleSubmit}>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>Name <span className="icon-danger">*</span></Label>
                        <Input placeholder="Name" name="name" onChange={handleChange} />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Email <span className="icon-danger">*</span></Label>
                        <Input placeholder="Email" name="email" type="email" onChange={handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label>Subject <span className="icon-danger">*</span></Label>
                    <Input
                      name="subject"
                      placeholder="Subject"
                      type="text"
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Message <span className="icon-danger">*</span></Label>
                    <Input
                      name="message"
                      placeholder="Let us know what's on your mind..."
                      type="textarea"
                      rows="4"
                      onChange={handleChange}
                    />
                  </FormGroup>

                  <Row>
                    <Col className="text-center ml-auto mr-auto" md="8">
                      <SuperButton className="btn-fill" color="primary" size="lg" icon={submitIcon} shake={shake}>
                        Send Message
                      </SuperButton>
                      {errorMessage && <div className="text-center form-control-feedback">{errorMessage}</div>}
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="big-map" id="contactUsMap">
        <MapWrapper />
      </div>
    </App>
  );
}

export default ContactPage;
