import React from "react";
import App from 'components/App';
import { Card, CardBody, CardHeader, Container, UncontrolledCollapse } from "reactstrap";
import ExternalLink from "components/UI/ExternalLink";
import Icon from "components/UI/Icon";
import { Link } from "react-router-dom";
import { isAuthenticated } from "components/Restricted";

const faqs = [
  {
    question: 'Do I need insurance?',
    answer: (
      <>
        You will want an <strong>HO3 policy</strong> (basic homeowners insurance). Be careful while selecting your policy as most agents will recommend an HO6 policy. While HO6 policies are common for condominiums, the Association does not carry a policy that would cover the exterior / structure of your property. The Association’s master policy only covers communal / shared resources.
      </>
    )
  },
  {
    question: 'How do I pay monthly dues?',
    answer: (
      <>
        <ExternalLink href={process.env.REACT_APP_PROPERTY_MANAGEMENT_URL}>{process.env.REACT_APP_PROPERTY_MANAGEMENT}</ExternalLink> is our contracted property management company. You can setup and manage your HOA dues and payments through the <ExternalLink href={process.env.REACT_APP_PROPERTY_MANAGEMENT_URL}>account portal</ExternalLink>.
      </>
    )
  },
  process.env.REACT_APP_FEATURE_MEMBERS_VENDORS && {
    question: 'Can the HOA recommend contractors / service providers?',
    answer: (
      <>
        The HOA does not directly provide recommendations, however, here is a list of member <Link to="/members/vendors">curated and recommended vendors</Link>.
      </>
    )
  },
  {
    question: 'How do I set up my electricity / gas account?',
    answer: (
      <>
        Gas and Electricity are provided through <ExternalLink href="https://myaccount.sdge.com/portal/ServiceRequest/moveIn">San Diego Gas &amp; Electric (SDG&amp;E)</ExternalLink>.
      </>
    )
  },
  {
    question: 'How do I set up my water?',
    answer: (
      <>
        Water is provided through the City of San Diego. You can setup and manage your account through <ExternalLink href="https://customerportal.sandiego.gov/portal/">MyWaterSD</ExternalLink>.
      </>
    )
  },
  {
    question: 'When is garbage collected?',
    answer: (
      <>
        <strong>Black bins</strong> (trash) are collected every Monday, while <strong className="text-info">blue bins</strong> (recyclables) are collected every other Monday. See the <ExternalLink href="https://getitdone.force.com/CollectionDetail?id=a4Ot0000000eNiUEAU">Collection Schedule</ExternalLink> website or <ExternalLink href="https://www.sandiego.gov/sites/default/files/legacy/environmental-services/collection/pdf/curbsidecalendar-orange-recycleonly.pdf">Collection Calendar</ExternalLink> for holidays or other schedule adjustments.
      </>
    )
  },
  {
    question: 'How do I secure a parking space?',
    answer: (
      <>
        Please use the <Link to="/members/parking">Parking Permits Request</Link> form to request a parking permit for the private lot and the end of our street. This permits the parking of one (1) vehicle in the private lot, but does not guarantee availability (first-come basis). You may park in your driveway as long as it does not block other vehicles from reasonably passing.
      </>
    )
  },
  {
    question: 'Is guest parking available?',
    answer: (
      <>
        Guest parking rules are the same as resident parking. All vehicles should be parked in a way that does not encroach on the landscaping and does not block other vehicles from reasonably entering/exiting. Guests may park along the street as long as they do not block other vehicles and do not unreasonably block neighboring walkways.
      </>
    )
  },
  {
    question: 'How can I report an issue, violation, or complaint?',
    answer: (
      <>
        While we hope all disputes can be resolved amicably between owner(s)/resident(s), we understand that there may be need for the Association to intervene or enforce binding Rules and Regulations in our Community Rules.
        <br/><br/>
        Please use the <Link to="/members/issue">Issues form</Link> to file any formal complaints with the <Link to="/board">Board of Directors</Link>.
      </>
    )
  },
].filter(Boolean);

function FAQ({ faqs }) {
  const [collapseState, setCollapseState] = React.useState(false);

  function toggleCollapse(index) {
    setCollapseState({
      ...collapseState,
      [index]: !collapseState[index],
    });
  }

  const authenticated = isAuthenticated();

  return (
    <div aria-multiselectable={true} id="accordion" role="tablist">
      <Card className="no-transition">
        {faqs.map(({ question, answer, restricted }, index) => {
          if (restricted && !authenticated) {
            return null;
          }
          const headingID = `faq-heading-${index}`;
          const toggleID = `faq-toggle-${index}`;
          return (
            <div key={headingID}>
              <CardHeader
                className="card-collapse"
                id={headingID}
                role="tab"
              >
                <h5 className="mb-0 panel-title">
                  <a
                    aria-expanded={collapseState[index]}
                    href={`#${headingID}`}
                    id={toggleID}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      toggleCollapse(index);
                    }}
                  >
                    {question} <Icon icon="nc-minimal-down" />
                  </a>
                </h5>
              </CardHeader>
              <UncontrolledCollapse
                aria-labelledby={headingID}
                role="tabpanel"
                toggler={`#${toggleID}`}
              >
                <CardBody>
                  {answer}
                </CardBody>
              </UncontrolledCollapse>
            </div>
          );
        })}
      </Card>
    </div>
  );
}

export default function FAQPage() {
  return (
    <App title="Frequently Asked Questions" pageKey="faq-page" className="about-us">
      <div className="main">
        <Container>
          <div className="section">
            <h2 className="title">Frequently Asked Questions</h2>
            <div className="faq">
              <FAQ faqs={faqs}/>
            </div>
          </div>
        </Container>
      </div>
    </App>
  );
}
