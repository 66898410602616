import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
import SuperButton from "components/UI/SuperButton";
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";
// core components

import { isAuthenticated } from 'components/Restricted';
import Icon from "components/UI/Icon";
import memberResources from "utils/memberResources";

function GlobalNavbar(props) {
  const [navbarClass, setNavbarClass] = React.useState(props.transparent ? 'navbar-transparent' : '');
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const authenticated = isAuthenticated();

  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialize
    headroom.init();
    if (props.transparent) {
      const updateNavbar = () => {
        if (
          document.documentElement.scrollTop > 499 ||
          document.body.scrollTop > 499
        ) {
          setNavbarClass("");
        } else if (
          document.documentElement.scrollTop < 500 ||
          document.body.scrollTop < 500
        ) {
          setNavbarClass("navbar-transparent");
        }
      };
      window.addEventListener("scroll", updateNavbar);
      return function cleanup() {
        window.removeEventListener("scroll", updateNavbar);
      };
    }
  });
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={classnames("fixed-top", navbarClass)}
        expand="lg"
        id="navbar-main"
        color={props.color}
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to="/" tag={Link}>
              Soledad Westview
            </NavbarBrand>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link to="/" className="nav-link">Home</Link>
              </NavItem>
              <NavItem>
                <Link to="/about" className="nav-link">About</Link>
              </NavItem>
              <NavItem>
                <Link to="/faq" className="nav-link">FAQ</Link>
              </NavItem>
              <NavItem>
                <Link to="/people" className="nav-link">People</Link>
              </NavItem>
              <NavItem>
                <Link to="/documents" className="nav-link">Documents</Link>
              </NavItem>
              {props.demo && <>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle className="mr-2" color="default" caret nav>
                    Components
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-danger" right>
                    <DropdownItem to="/index" tag={Link}>
                      All Components
                    </DropdownItem>
                    <DropdownItem to="/presentation" tag={Link}>
                      Presentation
                    </DropdownItem>
                    <DropdownItem
                      href="https://demos.creative-tim.com/paper-kit-pro-react/#/documentation/introduction?ref=pkpr-color-navbar"
                      target="_blank"
                    >
                      Documentation
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle className="mr-2" color="default" caret nav>
                    Sections
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-danger" right>
                    <DropdownItem to="/sections#headers" tag={Link}>
                      <Icon icon="nc-tile-56" />
                      Headers
                    </DropdownItem>
                    <DropdownItem to="/sections#features" tag={Link}>
                      <Icon icon="nc-settings" />
                      Features
                    </DropdownItem>
                    <DropdownItem to="/sections#blogs" tag={Link}>
                      <Icon icon="nc-bullet-list-67" />
                      Blogs
                    </DropdownItem>
                    <DropdownItem to="/sections#teams" tag={Link}>
                      <Icon icon="nc-single-02" />
                      Teams
                    </DropdownItem>
                    <DropdownItem to="/sections#projects" tag={Link}>
                      <Icon icon="nc-calendar-60" />
                      Projects
                    </DropdownItem>
                    <DropdownItem to="/sections#pricing" tag={Link}>
                      <Icon icon="nc-money-coins" />
                      Pricing
                    </DropdownItem>
                    <DropdownItem to="/sections#testimonials" tag={Link}>
                      <Icon icon="nc-badge" />
                      Testimonials
                    </DropdownItem>
                    <DropdownItem to="/sections#contact-us" tag={Link}>
                      <Icon icon="nc-mobile" />
                      Contact Us
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle color="default" caret nav>
                    Examples
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-danger" right>
                    <DropdownItem to="/about-us" tag={Link}>
                      <Icon icon="nc-bank" />
                      About-us
                    </DropdownItem>
                    <DropdownItem to="/add-product" tag={Link}>
                      <Icon icon="nc-basket" />
                      Add Product
                    </DropdownItem>
                    <DropdownItem to="/blog-post" tag={Link}>
                      <Icon icon="nc-badge" />
                      Blog Post
                    </DropdownItem>
                    <DropdownItem to="/blog-posts" tag={Link}>
                      <Icon icon="nc-bullet-list-67" />
                      Blog Posts
                    </DropdownItem>
                    <DropdownItem to="/contact-us" tag={Link}>
                      <Icon icon="nc-mobile" />
                      Contact Us
                    </DropdownItem>
                    <DropdownItem to="/discover" tag={Link}>
                      <Icon icon="nc-world-2" />
                      Discover
                    </DropdownItem>
                    <DropdownItem to="/e-commerce" tag={Link}>
                      <Icon icon="nc-send" />
                      Ecommerce
                    </DropdownItem>
                    <DropdownItem to="/landing-page" tag={Link}>
                      <Icon icon="nc-spaceship" />
                      Landing Page
                    </DropdownItem>
                    <DropdownItem to="/login-page" tag={Link}>
                      <Icon icon="nc-lock-circle-open" />
                      Login Page
                    </DropdownItem>
                    <DropdownItem to="/product-page" tag={Link}>
                      <Icon icon="nc-album-2" />
                      Product Page
                    </DropdownItem>
                    <DropdownItem to="/profile-page" tag={Link}>
                      <Icon icon="nc-single-02" />
                      Profile Page
                    </DropdownItem>
                    <DropdownItem to="/register-page" tag={Link}>
                      <Icon icon="nc-bookmark-2" />
                      Register Page
                    </DropdownItem>
                    <DropdownItem to="/search-with-sidebar" tag={Link}>
                      <Icon icon="nc-zoom-split" />
                      Search Page
                    </DropdownItem>
                    <DropdownItem to="/settings" tag={Link}>
                      <Icon icon="nc-settings-gear-65" />
                      Settings Page
                    </DropdownItem>
                    <DropdownItem to="/twitter-redesign" tag={Link}>
                      <Icon icon="nc-tie-bow" />
                      Twitter
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>}
              <NavItem>
                <Link to="/contact" className="nav-link">Contact</Link>
              </NavItem>
              {authenticated && <>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle color="default" caret nav>
                    Members
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-danger" right>
                    {memberResources.map(({ icon, link, label }, i) => {
                      return (
                       <DropdownItem to={link.to} tag={link.tag || Link} key={i}>
                          <Icon icon={icon} /> {label}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>}
              <NavItem>
                <SuperButton
                  linkTo="/members"
                  className="btn-round mr-1"
                  color="danger"
                  icon="nc-single-02"
                >
                  Member Portal
                </SuperButton>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default GlobalNavbar;
