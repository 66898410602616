import React from "react";
import App from 'components/App';
import { Card, CardBody, CardTitle, Col, Container, Row, Modal, ModalBody } from "reactstrap";
import officerPositions from 'utils/officerPositions';
import { Link } from "react-router-dom";

const placeholders = {
  male: require("assets/img/board-members/placeholder-male.png").default,
  female: require("assets/img/board-members/placeholder-female.jpg").default,
};

const boardMembers = [
  {
    name: "Eugene O'Neill",
    positions: [officerPositions.president],
    image: placeholders.male,
    description: 'TODO: description',
  },
  {
    name: "Catey Truza",
    positions: [officerPositions.vp, officerPositions.secretary],
    image: placeholders.female,
    description: 'TODO: description',
  },
  {
    name: "Corbin Donnelley",
    positions: [officerPositions.cfo],
    image: placeholders.male,
    description: 'TODO: description',
  }
];

const architecturalCommittee = [
  // {
  //   name: "Eugene O'Neill",
  //   image: placeholders.male,
  // },
  // {
  //   name: "Catey Truza",
  //   image: placeholders.female,
  // },
];

function PositionsModal({ positions, isOpen, onClose }) {
  if (!positions?.length) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg">
      <div className="modal-header">
        <button
          className="close"
          type="button"
          onClick={onClose}
          title="Close"
        >
          <span>×</span>
        </button>
        <h5
          className="modal-title"
        >
          Duties &amp; Responsibilities
        </h5>
      </div>
      <ModalBody>
        {positions.map(({ title, description }) => {
          return (
            <div key={title}>
              <h3>{title}</h3>
              <p>{description}</p>
            </div>
          );
        })}
      </ModalBody>
    </Modal>
  );
}

function PropertyManagement() {
  return (
    <div id="property-manager"></div>
  );
}

function ArchitecturalCommittee() {
  if (!architecturalCommittee.length) {
    return null;
  }

  const colSize = Math.max(12 / architecturalCommittee.length, 5);

  return (
    <div id="architectural-committee">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <h2 className="title">Architectural Control Committee</h2>
            <div className="description">
              <p>
                The Architectural Control Committee is responsible to the Board of Directors for design review, for recommending action by the Board on properly submitted applications, and on violations of established policies.
              </p>
              <p>
                The Architectural Control Committee will review and authorize <Link to="/members/change-request">Change Request</Link> applications.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          {architecturalCommittee.map(({ name, title, image }) => {
            return (
              <Col md={colSize} key={name}>
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <img alt={name} src={image} />
                  </div>
                  <CardBody>
                    <div className="author">
                      <CardTitle tag="h4">{name}</CardTitle>
                      {title && (
                        <h6 className="card-category text-muted">
                          {title}
                        </h6>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

function NominatingCommittee() {
  return (
    <div id="nominating-committee"></div>
  );
}

function BoardOfDirectors() {
  const [positions, setPositions] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);
  const colSize = Math.max(12 / boardMembers.length, 4);

  return (
    <div id="board">
      <PositionsModal positions={positions} isOpen={isOpen} onClose={() => {setOpen(false)}} />
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <h2 className="title">Board of Directors</h2>
            <div className="description">
              <p>The Soledad Westview Board of Directors are residents and homeowners who volunteer their time and expertise managing the Homeowners Association for the residents.</p>

              <p>The board's charter and primary duty is to preserve the integrity and maintain the appearance of the neighborhood by managing the following:</p>
              <ul>
                <li>Provide Financial Management and Neighborhood Planning</li>
                <li>Common Area Maintenance, Repairs, and Improvements</li>
                <li>Oversee the Declaration of Covenants and Rules Enforcement</li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          {boardMembers.map(({ name, positions, image }) => {
            return (
              <Col md={colSize} key={name}>
                <a href="#details" onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setPositions(positions)
                  setOpen(true)
                }}>
                  <Card className="card-profile card-plain">
                    <div className="card-avatar">
                      <img alt={name} src={image} />
                    </div>
                    <CardBody>
                      <div className="author">
                        <CardTitle tag="h4">{name}</CardTitle>
                        <h6 className="card-category text-muted">
                          {positions.map(({ title }) => {
                            return <div key={title}>{title}</div>;
                          })}
                        </h6>
                      </div>
                      {/* <p className="card-description text-center">{description}</p> */}
                    </CardBody>
                  </Card>
                </a>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default function PeoplePage() {
  return (
    <App title="People" pageKey="people-page" className="about-us">
      <div className="main">
        <div className="section">
          <BoardOfDirectors/>
          <ArchitecturalCommittee/>
          <NominatingCommittee/>
          <PropertyManagement/>
        </div>
      </div>
    </App>
  );
}
