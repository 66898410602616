import React from "react";
import App from "components/App.js";
import chunkArray from "utils/chunkArray";
import memberResources from "utils/memberResources";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import Icon from "components/UI/Icon";

const chunks = chunkArray(memberResources, 4);

function MemberPortal() {
  return (
    <App title="Member Portal" pageKey="member-portal" restricted>
      <div className="wrapper">
        <div className="section text-center landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Nice to see you again.</h2>
                <h5>
                  Use the resources below to find important and helpful information about the Soledad Westview community.
                </h5>
              </Col>
            </Row>
            <br />
            <br />
            {chunks.map((resources, i) => {
              return (
                <Row key={i}>
                  {resources.map((resource, i) => {
                    const { icon, label, description, link } = resource;
                    const LinkTag = link.tag;
                    return (
                      <Col md="3" key={i}>
                        <LinkTag to={link.to}>
                          <div className="info">
                            <div className="icon icon-danger">
                              <Icon icon={icon} />
                            </div>
                            <div className="description">
                              <h4 className="info-title">{label}</h4>
                              <p className="description">
                                {description}
                              </p>
                            </div>
                          </div>
                        </LinkTag>
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </Container>
        </div>
      </div>
    </App>
  );
}

export default MemberPortal;
