import React from "react";
import App from "components/App.js";
import { Container, Spinner } from "reactstrap";

export default function EventsPage() {
  const [isLoading, setLoading] = React.useState(true);

  return (
    <App title="Events" pageKey="events-page" restricted>
      <div className="main">
        <Container>
          <div className="section">
            <h2 className="title">Upcoming Events</h2>
            <div style={{ position: 'relative' }}>
              <iframe
                title="Upcoming Events"
                src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FLos_Angeles&amp;showTitle=0&amp;showDate=1&amp;showPrint=0&amp;showTabs=0&amp;src=c29sZWRhZC53ZXN0dmlld0BnbWFpbC5jb20&amp;color=%23039BE5&amp;showCalendars=0"
                width="100%"
                height="600"
                frameBorder="0"
                scrolling="no"
                onLoad={() => {setLoading(false)}}
              ></iframe>
              {isLoading && <Spinner color="primary" style={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                margin: '0 auto',
              }}/>}
            </div>
          </div>
        </Container>
      </div>
    </App>
  );
}
