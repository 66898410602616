import React from "react";
import usePageTitle from "utils/usePageTitle";
import GlobalNavbar from "components/Navbars/GlobalNavbar";
import GlobalFooter from "components/Footers/GlobalFooter";
import Restricted from "components/Restricted";
import classnames from "classnames";

function getGlobalElement(value, defaultElement) {
  value = value ?? true;
  if (typeof value !== 'boolean') {
    return value;
  }
  return value ? defaultElement : null;
}

export default function App({ children: incomingChildren, title, restricted, globalNav, globalFooter, pageKey, className }) {
  usePageTitle(title);
  const [bodyClasses] = React.useState(classnames([pageKey, className]).split(' '));

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    bodyClasses.forEach(className => {
      document.body.classList.add(className);
    });
    return function cleanup() {
      bodyClasses.forEach(className => {
        document.body.classList.remove(className);
      });
    };
  }, [bodyClasses]);

  const children = (
    <>
      {getGlobalElement(globalNav, <GlobalNavbar/>)}
      {incomingChildren}
      {getGlobalElement(globalFooter, <GlobalFooter/>)}
    </>
  );

  return restricted ? <Restricted children={children}/> : children;
};
