import classnames from 'classnames';

const iconSets = {
  nc: 'nc-icon',
  fa: 'fa'
};

export default function Icon({ icon, className, ...props }) {
  const iconSet = icon.includes(' ') ? null : iconSets[icon.split('-')[0]];
  return <i className={classnames([iconSet, icon, className])} aria-hidden="true" {...props} />;
}
