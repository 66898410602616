import React from "react";
import App from "components/App.js";
import { Alert, Card, CardBody, CardHeader, Col, Container, Row, UncontrolledCollapse } from "reactstrap";
import { Link } from 'react-router-dom';
import preval from 'preval.macro';
import Icon from "components/UI/Icon";
import dateFormatter from "utils/dateFormatter";
import currencyFormatter from "utils/currencyFormatter";
import ExternalLink from "components/UI/ExternalLink";
import DetailsModal from "components/UI/DetailsModal";
import officerPositions from "utils/officerPositions";

// TODO: need to verify this is safe
const lastUpdated = preval`const fs = require('fs'); module.exports = fs.statSync(__filename).mtimeMs;`;

function DocumentLink({ document, page }) {
  const { to, label, pageOffset } = document;

  if (typeof page === 'number' && pageOffset) {
    page = page + pageOffset;
  }

  return (
    <ExternalLink to={[to, page].filter(Boolean).join('#page=')}>{label}</ExternalLink>
  );
}

const documents = {
  CCRs: {
    label: 'Covenants, Conditions and Restrictions',
    to: '/documents/CCRs',
  },
  bylaws: {
    label: 'Bylaws',
    to: '/documents/bylaws',
    pageOffset: 3,
  },
  delinquencyPolicy: {
    label: 'Delinquency Policy',
    to: '/documents/delinquencyPolicy',
  },
};

const sections = preprocessSections([
  {
    heading: 'Preface',
    content: (
      <>
        <p>The rules and regulations set forth in this handbook are the rules adopted by various Board of Directors in an effort to address the changing needs of the community while complying with the state laws, municipal ordinances, and our <Link to="/documents">founding documents</Link>.</p>
        <p>Nothing in this handbook is intended to contradict any of these laws or documents, but rather to clarify the regulations necessary to protect our common areas and/or property values.</p>
        <p>While not everyone will agree with every rule, each rule represents the best collective wisdom of our elected representatives.</p>
        <p>The purpose of this handbook is to make these rules and regulations readily available to each property owner, visitor, or tenant.</p>
      </>
    ),
    index: false,
  },
  {
    heading: 'Welcome',
    content: (
      <>
        <p>
          On behalf of the <Link to="/board">Board of Directors</Link> and the residents of Soledad Westview, we would like to welcome you to your new home and our community!
          We are glad to have you as a neighbor and would like to take this opportunity to give you some information about our community and Homeowners Association.
        </p>
        <p>
          The operation of the Soledad Westview Homeowners Association is governed by a volunteer <Link to="/board">Board of Directors</Link>.
        </p>
        <p>
          The Board of Directors is elected by the community to oversee the daily functions and financial responsibilities of the Association.
          The Board is also responsible for maintaining member compliance with the established covenants and bylaws of the Association.
        </p>
        <p>
          Please take the time to read through the <Link to="/documents">governing documents</Link>, and use this handbook as a reference.
        </p>
        <p>
          Our community website – <Link to="/">soledadwestview.com</Link> – provides {process.env.REACT_APP_FEATURE_MEMBERS_RESOURCES && <><Link to="/members/resources">resident resources</Link>,</>} <Link to="/documents">governing documents</Link>, and <Link to="/contact">contact information</Link>.
          This website, including the <Link to="/faq">Frequently Asked Questions</Link>, <Link to="/members">Member Portal</Link>, and this <Link to="/documents/handbook">Handbook</Link>, provide answers to most of the questions homeowners have.
        </p>
        <p>
          <ExternalLink href={process.env.REACT_APP_PROPERTY_MANAGEMENT_URL}>{process.env.REACT_APP_PROPERTY_MANAGEMENT}</ExternalLink> is our contracted property management company and handles HOA payments and other information relating to the management of our Association.
        </p>
        <p>
          Again, welcome to the neighborhood! We hope we have addressed your immediate needs in getting started with your new home and we look forward to meeting you.
        </p>
      </>
    ),
    index: false,
  },
  {
    heading: 'Administration',
    sections: [
      {
        heading: 'Membership',
        content: (
          <>
            <p>
              All homeowners are Acting Members of the Association. Acting Members have all right and responsibilities as defined by the <ExternalLink to="/documents/CCRs">CC&amp;Rs</ExternalLink> and other <Link to="/documents">governing documents</Link>.
            </p>
            <p>
              Members have the right to serve on the Board of Directors, attend board and member meetings, vote, dispute resolutions, and even sue the Association.
            </p>
          </>
        ),
        references: [
          {
            text: (
              <>
                <strong>Membership:</strong> The owner of a dwelling unit shall
                automatically, upon his becoming the owner of a condominium, be a
                member of the Association and shall remain a member thereof until
                such time as his ownership ceases for any reason, at which time
                his membership in the Association shall automatically cease.
              </>
            ),
            document: documents.CCRs,
            page: 9,
          }
        ],
        sections: [
          {
            heading: 'Observing Members',
            content: (
              <>
                <p>
                  Unlike homeowners (or Acting Members), renters, tenants, or other non-owner occupants (Observing Members) do not carry the same rights.
                </p>
                <p>
                  Observing Members cannot serve on the board, vote at meetings, dispute resolutions, or sue the Association.
                  However, Observing Members must still abide by the rules and regulations of the Association as it pertains to residents and occupants.
                </p>
              </>
            )
          }
        ]
      },
      {
        heading: 'Meetings',
        content: null,
      },
      {
        heading: 'Board of Directors',
        content: (
          <>
            <p>
              The <Link to="/board">Board of Directors</Link> are residents and homeowners who volunteer their time and expertise managing the Homeowners Association for the residents.
              Board members are nominated and elected by the <a href="#membership">voting members</a> of the Association.
              Board members serve a one year term with no <em>term limit</em>.
            </p>
            <p>
              The Board of Directors will consist of the <strong>President</strong>, <strong>Vice President</strong>, <strong>Chief Financial Officer</strong>, and <strong>Secretary</strong>.
            </p>
          </>
        ),
        sections: [
          {
            heading: 'Election',
            content: (
              <>
                <p>
                  The <Link to="/people#nominating-committee">Nominating Committee</Link> and/or members of the Association shall nominate officers for the Board of Directors at each <a href="#annual-meeting">Annual Meeting</a>.
                  The nominee receiving the largest number of votes from the <a href="#membership">voting members</a> shall be elected.
                </p>
              </>
            )
          },
          {
            heading: 'Responsibilities & Duties',
            content: (
              <>
                <p>The board's charter and primary duty is to preserve the integrity and maintain the appearance of the neighborhood by managing the following:</p>
                <ul>
                  <li>Provide Financial Management and Neighborhood Planning</li>
                  <li>Common Area Maintenance, Repairs, and Improvements</li>
                  <li>Oversee the Declaration of Covenants and Rules Enforcement</li>
                </ul>
              </>
            ),
            references: [
              {
                text: ['president', 'vp', 'cfo', 'secretary'].map((office, i, array) => {
                  const { title, description } = officerPositions[office];
                  const isLast = i === array.length - 1;
                  return (
                    <div key={i}>
                      <strong>{title}</strong> - {description}
                      {!isLast && <hr/>}
                    </div>
                  );
                }),
                quote: false,
                document: documents.bylaws,
                page: 14
              }
            ],
          }
        ]
      },
      {
        heading: 'Architectural Control Committee',
        content: (
          <>
            <p>
              The Architectural Control Committee is responsible to the Board of Directors for design review, for recommending action by the Board on properly submitted applications, and on violations of established policies.
            </p>
            <p>
              The purpose of design review and architectural control is to preserve the Soledad Westview community in an attractive manner for the enjoyment of residents and for the protection of property values.
            </p>
            <p>
              The Architectural Control Committee will review and authorize <Link to="/members/change-request">Change Request</Link> applications.
            </p>
          </>
        )
      },
      {
        heading: 'Assessments, Dues, and Delinquency Policy',
        content: (
          <>
            <p>
              Regular Assessments are currently set at <strong>{currencyFormatter.format(process.env.REACT_APP_MONTHLY_DUES)} per month</strong> and due on the first day of each month.
            </p>
            <p>
              Special and/or Emergency Assessments may be authorized by the Board of Directors to address additional maintenance or repairs not covered by the Regular Assessments.
              Special Assessments shall not exceed 5% of the annual budget without a majority member approval.
            </p>
            <p>
              <ExternalLink href={process.env.REACT_APP_PROPERTY_MANAGEMENT_URL}>{process.env.REACT_APP_PROPERTY_MANAGEMENT}</ExternalLink> is our contracted property management company.
              You can setup and manage your HOA dues and payments through the <ExternalLink href={process.env.REACT_APP_PROPERTY_MANAGEMENT_URL}>account portal</ExternalLink>.
            </p>
            <p>
              See the <ExternalLink to="/documents/delinquencyPolicy">Delinquency Policy</ExternalLink> for details on the handling of late or delinquent payments.
            </p>
          </>
        ),
        references: [
          {
            document: documents.CCRs,
            page: [25,34]
          },
          {
            document: documents.delinquencyPolicy,
            page: [1,2]
          }
        ]
      },
      {
        heading: 'Rules Enforcement',
        content: (
          <>
            <p>
              The Board of Directors has power to enforce all applicable rules and provisions of governing documents and holds the right to impose disciplinary action upon violation of said rules and provisions.
            </p>
            <p>
              Residents are requested to <Link to="/members/issues">report violations</Link> of the governing documents to the Board of Directors.
              Upon receipt or knowledge of an alleged violation, the Board of Directors will determine whether to pursue the reported violation.
            </p>
          </>
        ),
        references: [
          {
            text: (
              <>
                The Board shall have power to: (1) enforce all applicable provisions of this Declaration and all other related instruments or documents with respect to the management or control cf the Protect, and in so doing exercise for the Association all powers, duties and authority vested in or designated to the Association and not reserved to the membership by other provisions of this Declaration.
              </>
            ),
            document: documents.CCRs,
            page: 18
          }
        ]
      },
    ]
  },
  {
    heading: 'Community Rules',
    sections: [
      {
        heading: 'Pet Regulations',
        content: (
          <>
            <p>
              Dogs, cats, and other small household pets are allowed.
              Owners or handlers must maintain control of pets in public and Common Areas, and are responsible for cleaning up any pet waste.
            </p>
            <p>
              Please also familiarize yourself with the <ExternalLink href="https://www.sddac.com/content/sdc/das/license-laws/laws.html">San Diego Pet Ownership Laws</ExternalLink> regarding your rights and responsibilities as a pet owner.
            </p>
          </>
        ),
        references: [
          {
            text: (
              <>
                <strong>Animals:</strong> No animals of any kind shall be raised, bred,
                or kept in any dwelling unit, or on any portion of the Project,
                except that two (2) dogs, or cats, no larger than thirty (30)
                pounds, may be kept, provided that they are not kept, bred or
                maintained for any commercial purposes, and further provided that
                they are kept under reasonable control at all times. Not
                withstanding the foregoing, no pets may be kept on the Project
                which, in the determination of the Board, result in an
                unreasonable annoyance in the Common Area, except as permitted by
                rules of the Board. No dog shall enter the Common Area except
                while on a leash which is held by a person capable of controlling
                the dog. Owners shall prevent their pets from soiling all
                portions of the Common Area where other persons customarily walk.
              </>
            ),
            document: documents.CCRs,
            page: [40,41]
          }
        ]
      },
      {
        heading: 'Streets, Vehicles, and Parking',
        content: [
          'Please use caution and maintain a safe speed while driving on our private street. There are young children and pets present.',
        ],
        sections: [
          {
            heading: 'Vehicles',
            content: (
              <>
                <p>
                  Generally, oversized or commercial vehicles should not be parked on the property.
                  Pickup trucks and <em>company cars</em> are fine, just nothing excessive in size or "offensive".
                  See the CC&amp;R reference below for full details.
                </p>
                <p>
                  For large obstructions (delivery trucks, flatbed trucks, heavy machinery, moving pods, pallets, dumpsters, etc), please work the vehicle operator and your neighbors to reduce any disruption or impact.
                </p>
                <p>
                  Don't block your neighbor's driveway or street access and you'll be fine.
                </p>
              </>
            ),
            references: [
              {
                text: (
                  <>
                    <strong>Vehicle Restrictions:</strong> No trailer, camper, mobile home,
                    commercial vehicle, truck (other than standard size pickup
                    truck), inoperable automobile, boat or similar equipment shall be
                    permitted to remain upon any area within the Project, other than
                    temporarily. Commercial vehicles shall not include sedans or
                    standard size pickup trucks which are used both for business and
                    personal use, provided that any signs or markings of a commercial
                    nature on such vehicles shall be unobtrusive and inoffensive as
                    determined by the Board. No noisy or smoky vehicles shall be
                    operated on the Project. No off-road unlicensed motor vehicles
                    shall be operated upon the Project.
                  </>
                ),
                document: documents.CCRs,
                page: 40,
              }
            ]
          },
          {
            heading: 'Parking',
            content: (
              <>
                <p>
                  You may park in any of the following locations:
                </p>
                <ul>
                  <li>within your garage</li>
                  <li>in your driveway</li>
                  <li>in the private (<a href="#parking-permits">permitted</a>) lot</li>
                  <li>along the private street</li>
                  <li>along Loring St or Pendleton St</li>
                </ul>
                <p>
                  All vehicles should be parked in a way that does not encroach on the landscaping and does not block other vehicles from reasonably entering / exiting the neighborhood.
                  Vehicles may be parked along the street as long as they do not block other vehicles and do not unreasonably block neighboring walkways.
                </p>
              </>
            ),
          },
          {
            heading: 'Parking Permits',
            content: (
              <p>
                Please use the <Link to="/members/parking">Parking Permits Request</Link> form to request a parking permit for the private lot at the end of our street. This permits the parking of one (1) vehicle in the private lot, but does not guarantee availability (first-come basis).
              </p>
            )
          }
        ].filter(Boolean)
      },
      {
        heading: 'Rental Policy',
        content: [
          'Units may be rented on a long-term basis (more than 30 days) to tenants so long as the Association is notified of any change of tenancy and tenant contact information is provided.',
          'Tenants must adhere to the Community Rules as any other resident would.',
        ],
        sections: [
          {
            heading: 'Short-Term Rentals',
            content: (
              <>
                <p>
                  Short-term rentals (such as Airbnb, Vrbo, etc) that are less than 30 days in duration are prohibited under the CC&amp;Rs.
                  This is in compliance with <ExternalLink href="https://www.davis-stirling.com/HOME/H/HOA-Rent-Restrictions-Prohibitions">Davis-Stirling</ExternalLink> regulations.
                </p>
                <p>
                  Any short-term rental must also comply with San Diego's <ExternalLink href="https://www.sandiego.gov/treasurer/short-term-residential-occupancy"> Short-Term Residential Occupancy</ExternalLink> regulations.
                </p>
              </>
            ),
            references: [
              {
                text: (
                  <>
                    <strong>Right to Lease:</strong> The respective condominiums shall be used only as single family residences and shall not be rented by the Owners thereof for transient or hotel purposes, which is defined as (a) rental for any period less than thirty (30) days, or (b) any rental if the occupants of the dwelling unit are provided customary hotel services such as room service for food and beverage, maid service, furnishing laundry and linen, and bellboy services.
                  </>
                ),
                document: documents.CCRs,
                page: [41,42],
              }
            ],
          }
        ]
      },
      {
        heading: 'Maintenance Responsibilities',
        content: (
          <>
            <p>
              Homeowners must maintain, repair and/or replace exterior structural and ornamental aspects of the unit to keep the unit in good and working condition.
              This includes maintaining walls, balconies, gutters, doors, windows, trim, light fixtures, and roofs.
            </p>
            <p>
              Significant changes made in style, dimension, and/or material during repair and/or replacement must be approved by the <a href="#architectural-control-committee">Architectural Control Committee</a> via a <Link to="/members/change-request">Change Request</Link> application.
            </p>
            <p>
              Homeowners are not responsible for the maintenance of the Common Areas such as <a href="#landscape-policy">Common Area landscapes</a>, asphalt, plumbing and sewage lines beyond the boundaries of the unit.
            </p>
            <p className="text-danger">TODO: Need to confirm if owners are responsible for driveway, walkway (saltillo tile), and/or plumbing/sewage.</p>
          </>
        ),
      },
      {
        heading: 'Painting Policy',
        content: null,
      },
      {
        heading: 'Landscape Policy',
        content: (
          <>
            <p>
              The Association maintains all Common Area landscape.
              The Association <em>does not</em> maintain the landscape within Exclusive Use Areas (i.e. your backyard).
            </p>
            <p>
              For any issues or concerns with the landscape, please file an <Link to="/members/issues">Issue Report</Link> for the Board of Directors to review.
            </p>
          </>
        ),
        sections: [
          {
            heading: 'Exclusive Use Area Landscape',
            content: (
              <>
                <p>
                  The Association reserves the right to enforce the Maintenance of Exclusive Use Areas to preserve the safety and value of the community.
                </p>
                <p>
                  Proposed <em>structural</em> changes to the Exclusive Use Area landscape should be formally submitted for review via a <Link to="/members/change-request">Change Request</Link> application.
                </p>
              </>
            )
          },
          {
            heading: 'Common Area Landscape',
            content: (
              <p>
                Members shall not alter the Common Area landscape in a noticeable way without prior permission.
                Proposed changes to the Common Area landscape should be formally submitted for review via a <Link to="/members/change-request">Change Request</Link> application.
                Note that proposed changes to the Common Area landscape may be at the requestor's expense.
              </p>
            )
          }
        ]
      },
      {
        heading: 'Waste Storage and Collection',
        references: [
          {
            text: (
              <>
                <strong>Garbage and Refuse Disposal:</strong> All rubbish, trash, and garbage shall be regularly removed from the Project, and shall not be allowed to accumulate thereon. All trash, garbage and other waste shall be kept in sanitary containers. All trash enclosures or other equipment for the storage or disposal of such materials shall be kept in a clean and sanitary condition. All equipment, woodpiles, or storage piles shall be kept screened and concealed from the view of the other dwelling units, streets and the Common Area.
              </>
            ),
            document: documents.CCRs,
            page: 41,
          }
        ],
        content: (
          <>
            <p>
              All waste (trash, recyclables, household hazardous waste, etc) should be placed in the appropriate containers (blue / black bins, etc) and stored <strong>within the resident's garage</strong>.
            </p>
            <p>
              City collection bins (blue &amp; black bins) should not be left in public view for any duration of time except for the day before and the day of collection (Sunday and Monday, respectively).
            </p>
            <p>
              City collection bins should be brought to the curbside on the <strong>main Loring St</strong> on the scheduled collection day.
            </p>
            <p>
              Waste collection is currently scheduled every Monday for trash (black bins) and every other Monday for recyclables (blue bins), excluding Holiday schedule adjustments.
            </p>
            <p>
              Waste collection is provided by the <ExternalLink href="https://www.sandiego.gov/environmental-services/collection">City of San Diego Environmental Services</ExternalLink>.
            </p>
          </>
        ),
        sections: [
          {
            heading: 'Household Hazardous Waste & Electronic Waste',
            content: (
              <>
                <p>
                  All Hazardous Waste should be <em>temporarily</em> stored appropriately so as not to pose a health or safety risk to the residents or community.
                </p>
                <p>
                  Please dispose of <ExternalLink href="https://www.sandiego.gov/environmental-services/ep/hazardous">Household Hazardous Waste</ExternalLink> and <ExternalLink href="https://www2.calrecycle.ca.gov/Electronics/eRecycle">Electronic Waste (e-Waste)</ExternalLink> appropriately.
                </p>
                <p>
                  <em>Keep an eye out for coordinated community events for Hazardous and Electronic waste disposal.</em>
                </p>
              </>
            )
          }
        ]
      },
      {
        heading: 'Prohibited Activity',
        content: null,
      },
    ]
  }
]);

function getSectionID(heading) {
  return heading.toLowerCase().replace(/[^a-z]+/g, '-');
}

function preprocessSections(sections, parentIndex, depth = 0) {
  if (!sections?.length) {
    return;
  }
  let sectionIndex = 0;
  return sections.map(({sections, ...section}) => {
    const index = section.index !== false && [parentIndex, ++sectionIndex].filter(Boolean).join('.');
    return {
      meta: {
        id: getSectionID(section.heading),
        index,
        prefix: index && `${index}. `,
        depth: depth,
      },
      sections: preprocessSections(sections, index, depth + 1),
      ...section,
    }
  });
}

function Heading({ meta, children }) {
  const { depth, id, prefix } = meta;
  const Tag = `h${(depth || 0) + 3}`;
  return <Tag id={id || null}>{prefix}{children}</Tag>;
}



function Sections({ sections, updateModal }) {
  if (!sections?.length) {
    return null;
  }
  return sections.map(({references, id, heading, content, sections, meta}, i) => {
    if (typeof content === 'string') {
      content = (<p>{content}</p>);
    } else if (Array.isArray(content)) {
      content = content.map((line, i) => <p key={i}>{line}</p>);
    } else if (content === null) {
      content = (<p className="text-danger">In progress... Check back soon!</p>);
    }
    const referencesModal = references && {
      title: heading,
      body: references.map(({ quote, text, document, page }, i) => {
        page = [].concat(page).filter(Boolean);
        return (
          <blockquote className="blockquote" key={i}>
            {text && (
              <p>
                {quote === false ? text : <>"{text}"</>}
              </p>
            )}
            {document && (
              <footer className="small">
                - <DocumentLink document={document} page={page[0]}/> <span className="small">(p{page.join(' - ')})</span>
              </footer>
            )}
          </blockquote>
        );
      })
    };
    return (
      <div id={id} key={i}>
        <Heading meta={meta}>{heading}</Heading>
        {content}
        {referencesModal &&
          <div>
            <a href="#references" className="title-uppercase" onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              updateModal(referencesModal);
            }}>
              <strong><Icon icon="nc-pin-3"/> See References</strong>
            </a>
          </div>
        }
        <Sections sections={sections} updateModal={updateModal}/>
        {!meta?.depth && <hr/>}
      </div>
    );
  });
}

function TableOfContentsItems({ sections, maxDepth }) {
  if (!sections?.length) {
    return null;
  }

  return (
    <ul style={{listStyleType: 'none'}}>
      {sections.map(({ sections, heading, meta }, i) => {
        const { depth, prefix } = meta;
        if (!meta?.id || depth >= maxDepth) {
          return null;
        }
        return (
          <li key={i}>
            <a href={`#${meta?.id}`}>{prefix}{heading}</a>
            <TableOfContentsItems sections={sections}/>
          </li>
        );
      })}
    </ul>
  );
}

function TableOfContents({ sections, maxDepth = Infinity }) {
  const [isOpen, setOpen] = React.useState(false);
  return (
    <div id="accordion" role="tablist">
      <Card className="no-transition">
        <CardHeader
          className="card-collapse"
          id="toc-heading"
          role="tab"
        >
          <h5 className="mb-0 panel-title">
            <a
              aria-expanded={isOpen}
              href="#toc-heading"
              id="toc-collapse"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(!isOpen);
              }}
            >
              <Icon icon="fa-stream" className="normal-icon"/> Table of Contents <Icon icon="nc-minimal-down" />
            </a>
          </h5>
        </CardHeader>
        <UncontrolledCollapse aria-labelledby="toc-heading" role="tabpanel" toggler="#toc-collapse">
          <CardBody>
            <TableOfContentsItems sections={sections} maxDepth={maxDepth}/>
          </CardBody>
        </UncontrolledCollapse>
      </Card>
    </div>
  );
}

export default function HandbookPage() {
  const [modal, setModal] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);
  function updateModal(modal) {
    setModal(modal);
    setOpen(!!modal);
  }

  return (
    <App title="Member Handbook" pageKey="handbook-page" className="about-us" restricted>
      <DetailsModal size="lg" title={modal?.title} body={modal?.body} isOpen={isOpen} onClose={() => {
        updateModal(null);
      }}/>
      <div className="main">
        <div className="section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <h2 className="title">Member Handbook</h2>
                <Alert color="danger" className="alert-with-icon">
                  <div className="message"><Icon icon="nc-bell-55"/> This handbook is a <em>work in progress</em> and has not been approved by the Board of Directors.</div>
                </Alert>
                <Alert color="info" className="alert-with-icon">
                  <div className="message"><Icon icon="nc-bell-55"/> This handbook is not legally binding. Please use this document as a reference or interpretation of the legally binding founding documents (CC&amp;Rs, Bylaws, Articles of Incorporation, etc.).</div>
                </Alert>
                <TableOfContents sections={sections}/>
                <Sections sections={sections} updateModal={updateModal}/>
                <div className="small title-uppercase">Last Revised <em>{dateFormatter.format(lastUpdated)}</em></div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </App>
  );
}
