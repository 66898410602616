import React from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import setValue from 'set-value';
import App from "components/App.js";
import Icon from 'components/UI/Icon';
import useFormChange from "utils/useFormChange";
import { post } from "utils/api";

// reactstrap components
import {
  Alert,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";

// core components
import SuperButton from "components/UI/SuperButton.js";

function IssueDetails({ category, handleChange }) {
  category = category?.value;
  if (!category) {
    return null;
  }

  const formDetails = {
    side: null,
    bottom: null,
    main: null,
  };
  switch(category) {
    case 'resident-violation':
      formDetails.isStrict = true;
      formDetails.side = (<>
        <h3>Violator Information (Defendant)</h3>
        <FormGroup>
          <Label>
            <h6>Name <span className="icon-danger">*</span></h6>
          </Label>
          <Input
            name="defendant-name"
            className="border-input"
            placeholder="Full Name"
            type="text"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>
            <h6>Home Number <span className="icon-danger">*</span></h6>
          </Label>
          <Input
            name="defendant-unit"
            className="border-input"
            placeholder="Home Number (e.g. 2269)"
            type="text"
            onChange={handleChange}
          />
        </FormGroup>
        <Alert color="warning" className="alert-with-icon">
          <div className="message"><Icon icon="nc-bell-55"/> Please review the <Link to="/documents/handbook#community-rules" className="text-muted">Community Rules</Link> before submitting this complaint.</div>
        </Alert>
      </>);
      formDetails.main = (<>
        <h3>Violation / Complaint Details</h3>
        <FormGroup>
          <Label>
            <h6>Details of the Violation <span className="icon-danger">*</span></h6>
          </Label>
          <Input
            name="issue-description"
            placeholder="The nature and date of the alleged violation and a description of the factual basis of the complaint (Who, What, Where, When, etc.)..."
            type="textarea"
            rows="4"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>
            <h6>Regulation <span className="icon-danger">*</span></h6>
          </Label>
          <Input
            name="issue-regulation"
            placeholder="State the specific Rule or Regulation being violated - these are available in our Community Rules..."
            type="textarea"
            rows="2"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>
            <h6>Witness(es)</h6>
          </Label>
          <Input
            name="issue-witnesses"
            placeholder="If applicable, provide name and unit number of witnesses to the violation..."
            type="textarea"
            rows="2"
            onChange={handleChange}
          />
        </FormGroup>
      </>);
      formDetails.bottom = (<>
        <FormGroup check>
          <Label check>
            <Input
              defaultChecked
              defaultValue="yes"
              type="checkbox"
              name="ack-accuracy"
              onChange={handleChange}
            />
            <span className="form-check-sign" /> I hereby certify that all information and statements are true and correct to the best of my knowledge
          </Label>
        </FormGroup>
      </>);
      break;
    default:
      formDetails.side = (<>
        <h3>Issue Details</h3>
        <FormGroup>
          <Label>
            <h6>Describe the issue <span className="icon-danger">*</span></h6>
          </Label>
          <Input
            name="issue-description"
            placeholder="Please describe the issue in as much detail as possible..."
            type="textarea"
            rows="8"
            onChange={handleChange}
          />
        </FormGroup>
      </>);
      break;
  }

  return (
    <>
      <Row>
      <Col md="6">
        <h3>Your Information {formDetails.isStrict && '(Complainant)'}</h3>
        <FormGroup>
          <Label>
            <h6>Name <span className="icon-danger">*</span></h6>
          </Label>
          <Input
            name="reporter-name"
            className="border-input"
            placeholder="Full Name"
            type="text"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>
            <h6>Email <span className="icon-danger">*</span></h6>
          </Label>
          <Input
            name="reporter-email"
            type="email"
            className="border-input"
            placeholder="Email"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>
            <h6>Phone {formDetails.isStrict && <span className="icon-danger">*</span>}</h6>
          </Label>
          <Input
            name="reporter-phone"
            className="border-input"
            placeholder="Phone Number"
            type="tel"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>
            <h6>Home Number {formDetails.isStrict && <span className="icon-danger">*</span>}</h6>
          </Label>
          <Input
            name="reporter-unit"
            className="border-input"
            placeholder="Home Number (e.g. 2269)"
            type="text"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup radioGroup="reporter-status">
          <h6>Homeowner or Resident?</h6>
          <span className="form-check-radio">
            <Label check>
              <Input
                defaultValue="Resident"
                name="reporter-status"
                type="radio"
                onChange={handleChange}
              />
              <span className="form-check-sign" /> Resident{" "}
            </Label>
          </span>
          <span className="form-check-radio">
            <Label check>
              <Input
                defaultValue="Homeowner"
                name="reporter-status"
                type="radio"
                onChange={handleChange}
              />
              <span className="form-check-sign" /> Homeowner{" "}
            </Label>
          </span>
          <span className="form-check-radio">
            <Label check>
              <Input
                defaultChecked
                defaultValue="Both"
                name="reporter-status"
                type="radio"
                onChange={handleChange}
              />
              <span className="form-check-sign" /> Both{" "}
            </Label>
          </span>
        </FormGroup>
      </Col>

      <Col md="6">
        {formDetails.side}
      </Col>
    </Row>

    {formDetails.main}

    <FormGroup check>
      <Label check>
        <Input
          defaultChecked
          defaultValue="yes"
          type="checkbox"
          name="ack-board"
          onChange={handleChange}
        />
        <span className="form-check-sign" /> I understand this issue will be sent to the Board of Directors
      </Label>
    </FormGroup>
    {formDetails.bottom}
  </>
  )
}

export default function IssuePage() {
  const [hasSent, setSent] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [shake, setShake] = React.useState(false);
  const [categorySelected, setCategory] = React.useState(null);
  const [formData, handleChange] = useFormChange({
    'type': 'other',
    'reporter-status': 'Both',
    'ack-board': true,
    'ack-accuracy': true,
  });
  const [submitIcon, setSubmitIcon] = React.useState('fa-file-signature');

  function setError(message) {
    setErrorMessage(message);
    if (message) {
      setShake(Date.now());
    } else {
      setShake(false);
    }
  }

  function processFormData(category) {
    // validate
    const requiredFields = [
      'reporter-name',
      'reporter-email',
      'issue-description',
      'ack-board',
    ];

    if (category === 'resident-violation') {
      requiredFields.push.apply(requiredFields, [
        'reporter-phone',
        'reporter-unit',
        'defendant-name',
        'defendant-unit',
        'issue-regulation',
        'ack-accuracy',
      ]);
    }

    const hasMissing = requiredFields.some((key) => {
      return !formData[key];
    });

    if (hasMissing) {
      return;
    }

    return Object.entries(formData).reduce((data, [key, value]) => {
      return setValue(data, key, value, { separator: '-' });
    }, { category });
  }

  function handleSubmit(event) {
    event.preventDefault();
    const data = processFormData(categorySelected?.value);
    if (data) {
      setSubmitIcon('fa-hourglass-half');
      post('/reportIssue', data).then(() => {
        setSent(true);
        setError('');
        setSubmitIcon('nc-check-2');
      }).catch(() => {
        setError('Hmm, we seem to be having some issues processing your request. Please try again later.');
        setSubmitIcon('nc-simple-remove');
      });
    } else {
      setError('Please enter all required fields.');
    }
    setTimeout(() => {
      setSubmitIcon('fa-file-signature');
    }, 3000);
  }

  return (
    <App title="Issues" pageKey="issues-page" restricted>
      <div className="main">
        <Container>
          <div className="section">
            <h2 className="title text-center">Report an Issue</h2>
            <div className="description">
              <p>This is the Soledad Westview HOA official process for issues, complaints, or violation concerns to be addressed. All reports will be confidential and handled in a timely manner.</p>
            </div>
            <Alert color="success" isOpen={hasSent}>
              <Container>
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setSent(false)}
                >
                  <Icon icon="nc-simple-remove" />
                </button>
                <div className="message"><Icon icon="nc-check-2"/> Thank you for helping improve our community! We'll review the information provided and be in touch with you soon.</div>
              </Container>
            </Alert>

            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md="8">
                  <FormGroup>
                    <Label><h6>What's the nature of your issue?</h6></Label>
                    <Select
                      className="react-select"
                      classNamePrefix="react-select"
                      name="category"
                      value={categorySelected}
                      onChange={(value) => setCategory(value)}
                      options={[
                        { value: '', label: '-- SELECT AN OPTION BELOW --', isDisabled: true },
                        { value: "property-maintenance", label: "Communal property maintenance (e.g. landscaping, property damage, irrigation, etc)" },
                        { value: "resident-violation", label: "Violation of Community Rules" },
                        { value: "other", label: "Other", },
                      ]}
                      placeholder="Please select an option that most accurately describes the issue being reported..."
                    />
                  </FormGroup>
                </Col>
              </Row>

              <IssueDetails category={categorySelected} handleChange={handleChange}/>

              {categorySelected && <div className="text-center">
                <SuperButton className="btn-fill" color="danger" size="lg" icon={submitIcon} shake={shake}>
                  Submit
                </SuperButton>
                {errorMessage && <div className="text-center form-control-feedback">{errorMessage}</div>}
              </div>}
            </Form>
          </div>
        </Container>
      </div>
    </App>
  );
}
