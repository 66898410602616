import React from "react";
import App from "components/App.js";
import Icon from 'components/UI/Icon';
import useFormChange from "utils/useFormChange";
import { post } from "utils/api";

// reactstrap components
import {
  Alert,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";

// core components
import SuperButton from "components/UI/SuperButton.js";

export default function ParkingPermitPage() {
  const [hasSent, setSent] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [shake, setShake] = React.useState(false);
  const [formData, handleChange] = useFormChange({
    status: 'Both',
    subscribe: true
  });
  const [submitIcon, setSubmitIcon] = React.useState('fa-key');

  function setError(message) {
    setErrorMessage(message);
    if (message) {
      setShake(Date.now());
    } else {
      setShake(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (formData.email && formData.name && formData.unit) {
      setSubmitIcon('fa-hourglass-half');
      post('/parkingPermit', formData).then(() => {
        setSent(true);
        setError('');
        setSubmitIcon('nc-check-2');
      }).catch(() => {
        setError('Hmm, we seem to be having some issues processing your request. Please try again later.');
        setSubmitIcon('nc-simple-remove');
      });
    } else {
      setError('Please enter all required fields.');
    }
    setTimeout(() => {
      setSubmitIcon('nc-send');
    }, 3000);
  }

  return (
    <App title="Parking Permit" pageKey="parking-permit-page" restricted>
      <div className="main">
        <Container>
          <div className="section">
            <h2 className="title text-center">Parking Permit Request</h2>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={{ size: 8, offset: 2 }}>
                <Alert color="success" isOpen={hasSent}>
                  <Container>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                      onClick={() => setSent(false)}
                    >
                      <Icon icon="nc-simple-remove" />
                    </button>
                    <span><Icon icon="nc-check-2"/> Thanks! We'll get your parking permit to you soon.</span>
                  </Container>
                </Alert>
                  <FormGroup>
                    <Label>
                      <h6>Name <span className="icon-danger">*</span></h6>
                    </Label>
                    <Input
                      name="name"
                      className="border-input"
                      placeholder="Full Name"
                      type="text"
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <h6>Email <span className="icon-danger">*</span></h6>
                    </Label>
                    <Input
                      name="email"
                      type="email"
                      className="border-input"
                      placeholder="Email"
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <h6>Home Number <span className="icon-danger">*</span></h6>
                    </Label>
                    <Input
                      name="unit"
                      className="border-input"
                      placeholder="Home Number (e.g. 2269)"
                      type="text"
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="text-center">
                <SuperButton className="btn-fill" color="danger" size="lg" icon={submitIcon} shake={shake}>
                  Submit
                </SuperButton>
                {errorMessage && <div className="text-center form-control-feedback">{errorMessage}</div>}
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </App>
  );
}
