
export default function InfoCard({ title, icon, iconColor, children }) {
  iconColor = iconColor ?? 'icon-primary';
  return (
    <div className="info">
      {icon && <div className={`icon ${iconColor}`}>
        {icon}
      </div>}
      <div className="description">
        <h4 className="info-title">{title}</h4>
        <p className="description">{children}</p>
      </div>
    </div>
  );
}
